import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Error from "@wisetack/shared-ui/components/Error";
import useDashboardFlow from "../hooks/useDashboardFlow";

import styles from "./DashboardPage.module.scss";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import {LoanInfoItem} from "./LoanInfoItem";
import {submitData} from "../store/actions/consumerActions";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import {LoaderWithMessage} from "../components/LoaderWithMessage";

const ActiveLoanList = ({items, onClick}) => {
    if (!items) {
        return null;
    }
    return items.map(item => (
        <LoanInfoItem key={item.id} item={item} onClick={() => onClick(item)}/>
    ))
}

const pageName = "Dashboard Page";

function DashboardPage({submitData}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const submitDataRequestId = useSelector(state => state.consumer.submitDataRequestId);
    const firstName = useSelector(state => state.consumer.firstName);
    const transactionAmount = useSelector(state => state.consumer.transactionAmount);
    const profileFound = useSelector(state => state.consumer.profileFound);
    const loanLimitExceeded = useSelector(state => state.consumer.loanLimitExceeded);
    const expectedRunLength = useSelector(state => state.consumer.expectedRunLength);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const [selectedToken, setSelectedToken] = useState('')

    const submitFulfilled = !!submitDataRequestId && submitDataRequestId === requestId

    const {
        loanAppId,
        token,
        status,
        activeLoanList,
        logAmplitude,
        direct,
        history
    } = useDashboardFlow(pageName);

    useEffect(() => {
        if (selectedToken && submitFulfilled) {
            if (loanLimitExceeded) {
                history.push('/limit_exceeded')
            } else {
                direct(selectedToken)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitFulfilled, selectedToken, loanLimitExceeded]);

    const handleNewLoanClick = () => {
        if (token) {
            logAmplitude("Start a new loan link clicked")
            openLink(token, status, true)
        }
    }

    const handleActiveLoanClick = (item) => {
        if (item.initToken && !requestInProgress) {
            logAmplitude("Start an active loan link clicked", {token: item.initToken})
            openLink(item.initToken, item.status, false)
        }
    }

    const openLink = (token, appStatus, checkLoanLimit) => {
        setSelectedToken(token)
        const params = {}
        if (checkLoanLimit) {
            params.checkLoanLimit = true
        }
        if (appStatus === 'PENDING' && profileFound) {
            params.applyProfile = true
        }
        if (!!Object.keys(params).length) {
            // apply borrower profile to loan application or/and check loan limit
            submitData(loanAppId, params, newRequest());
        } else {
            direct(token)
        }
    }

    return <Container>
        { !requestInProgress ?
            <PageHeader progress="20%">
                <div>Welcome back{!!firstName ? `, ${firstName}` : ''}!</div>
                <div style={{textAlign: "center" }}>Select application in progress to continue.</div>
            </PageHeader>
            :
            <PageHeader progress="20%"/>
        }
        { !requestInProgress &&
            <>
                <ActiveLoanList items={activeLoanList} onClick={handleActiveLoanClick}/>
                { !!token && !!transactionAmount &&
                    <>
                        <div className={styles.m1}>Need to reapply?</div>
                        <div className={styles.l1} onClick={handleNewLoanClick}>
                            Start a new loan for {formatAmount(transactionAmount)}
                        </div>
                    </>
                }
            </>
        }
        <LoaderWithMessage isLoading={!!requestId && requestInProgress} duration={expectedRunLength}/>
        <Error pageName={pageName}>{errorMessage || requestError}</Error>
    </Container>
}

export default connect(null, {submitData})(
    DashboardPage
);
