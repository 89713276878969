import moment from "moment";
import uuid from "uuid";

import {
  APPLICATION_EXPIRED,
  CONSUMER_ACCEPT_OFFER,
  CONSUMER_ACCEPT_PLAID_TOKEN,
  CONSUMER_ACCEPT_TRUTH_IN_LENDING,
  CONSUMER_API_ERROR,
  CONSUMER_CHECKBOX_SELECTION,
  CONSUMER_CLEAR_STATE,
  CONSUMER_CREATE_LINK_TOKEN,
  CONSUMER_ERROR,
  CONSUMER_ESCALATE_AUTH,
  CONSUMER_GET_LOAN_STATUS,
  CONSUMER_GET_OFFERS,
  CONSUMER_GET_PERSONA_INQUIRIES,
  CONSUMER_GET_TRUTH_IN_LENDING,
  CONSUMER_LOCK_OFFER,
  CONSUMER_OFFER_SELECTED,
  CONSUMER_PREQUAL_GET,
  CONSUMER_PREQUAL_PATCH,
  CONSUMER_PREQUAL_PATCH_SELECT_OFFER,
  CONSUMER_PREQUAL_SUBMIT,
  CONSUMER_PURCHASE_RECEIVED,
  CONSUMER_SESSION_EXPIRED,
  CONSUMER_SUBMIT_DATA,
  CONSUMER_SUBMIT_PHONE,
  CONSUMER_SUBMIT_PIN,
  CONSUMER_UPDATE_LOAN,
  CONSUMER_GET_DOCUMENTS_CONTENT,
  EXPECTED_RUN_LENGTH,
  FIELD_ERROR,
  MERCHANT_CREATE_LOAN,
  SSN4_ERROR,
  CONSUMER_GET_CAPTURE_CONTEXT,
  CONSUMER_SUBMIT_CARD_DATA
} from "./types";

import {api, API_REQUEST_ENDED, API_REQUEST_STARTED,} from "@wisetack/shared-ui/utils/Api";

import axios from "axios";

const SELECTED_FINANCIAL_PRODUCT = "STANDARD";

const apiInvoke = async (
  method,
  path,
  data,
  dispatch,
  type,
  payloadUpdater,
  skipLoading,
  requestId
) => {
  try {
    let payload = await api(method, path, data, dispatch, skipLoading, requestId);
    if (payloadUpdater) {
      if (!payload) {
        payload = {}
      }
      payloadUpdater(payload);
    }
    dispatch({
      type,
      payload,
    });
  } catch (err) {
    if (requestId) {
      dispatch({
        type: CONSUMER_API_ERROR,
        payload: {requestId, error: err}
      });
      return;
    }
    const errMessage = err.message;
    try {
      const errResponse = JSON.parse(errMessage);
      dispatch({
        type: CONSUMER_ERROR,
        payload: errResponse
      });
    } catch (err) {
      // err.message is not in JSON format
      if (errMessage.startsWith("Invalid zip")) {
        dispatch({
          type: FIELD_ERROR,
          payload: { zip: errMessage },
        });
      } else if (errMessage.startsWith("Invalid state")) {
        dispatch({
          type: FIELD_ERROR,
          payload: { stateCode: errMessage },
        });
      } else if (errMessage.startsWith("Loan application not found")) {
        dispatch({
          type: APPLICATION_EXPIRED,
          payload: errMessage,
        });
      } else if (
        errMessage.includes(" ssn4 ") || errMessage.toLowerCase().includes("ssn4") ||
        errMessage.includes("accept the terms")
      ) {
        dispatch({
          type: SSN4_ERROR,
          payload: errMessage,
        });
      } else {
        dispatch({
          type: CONSUMER_ERROR,
          payload: errMessage,
        });
      }
    }
  }
};

// Should be moved to merchant application, it is here for testing purposes
export const createLoan = (data) => (dispatch) => {
  let merchantId = "111111111";
  // convert fields here to back-end format
  if (data.transactionAmount)
    data.transactionAmount = Number(data.transactionAmount);
  if (data.mobileNumber) {
    data.mobileNumber = data.mobileNumber.replace(/\D/g, "");
    if (data.mobileNumber.length === 10) {
      data.mobileNumber = "+1" + data.mobileNumber;
    } else if (data.mobileNumber.length === 11) {
      data.mobileNumber = "+" + data.mobileNumber;
    }
  }
  data.selectedFinancialProduct = SELECTED_FINANCIAL_PRODUCT;
  data.serviceCompletedOn = moment().format("YYYY-MM-DD");
  apiInvoke(
    "post",
    `/merchants/${merchantId}/loanApplications`,
    data,
    dispatch,
    MERCHANT_CREATE_LOAN
  );
};

export const getLoanStatus = (initToken, loanAppId, statusId, clearState) => (
  dispatch
) => {
  if (clearState) {
    dispatch({
      type: CONSUMER_CLEAR_STATE,
    });
  }
  dispatch({ type: EXPECTED_RUN_LENGTH, expectedRunLength:'short'});
  if (!loanAppId) loanAppId = initToken;
  apiInvoke(
    "get",
    `/loanapplications/${loanAppId}?initToken=${initToken}`,
    {},
    dispatch,
    CONSUMER_GET_LOAN_STATUS,
    (payload) => {
      payload.statusId = statusId;
      payload.initToken = initToken;
    }
  );
};

export const prequalSubmit = (signupId, prequalId, data, requestId) => async (dispatch) => {
  if (!signupId || !prequalId || !data) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Prequal data not specified.",
    });
    return;
  }
  apiInvoke(
      "post",
      `/signups/${signupId}/prequals/${prequalId}`,
      data,
      dispatch,
      CONSUMER_PREQUAL_SUBMIT,
      (payload) => {
        payload.requestId = requestId;
        payload.mobileNumber = data.mobileNumber
      },
      false,
      requestId
  );
}

export const prequalPatch = (applicationId, data, requestId) => async (dispatch) => {
  if (!applicationId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Prequal application ID not specified.",
    });
    return;
  }
  apiInvoke(
      "patch",
      `/prequals/${applicationId}`,
      data,
      dispatch,
      CONSUMER_PREQUAL_PATCH,
      (payload) => {
        payload.requestId = requestId;
      },
      false,
      requestId
  );
}

export const prequalPatchSelectOffer = (applicationId, data, requestId) => async (dispatch) => {
  if (!applicationId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Prequal application ID not specified.",
    });
    return;
  }
  apiInvoke(
      "patch",
      `/prequals/${applicationId}`,
      data,
      dispatch,
      CONSUMER_PREQUAL_PATCH_SELECT_OFFER,
      (payload) => {
        payload.requestId = requestId;
      },
      true,
      requestId
  );
}

export const signupPrequalGet = (signupId, prequalId, requestId, pinRequired) => async (dispatch) => {
  let path = `/signups/${signupId}/prequals/${prequalId}`;
  if (pinRequired) {
    path = path + "?pinRequired=true"
  }
  if (!signupId || !prequalId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Prequal ID or Signup ID not specified.",
    });
    return;
  }
  apiInvoke(
      "get",
      path,
      {},
      dispatch,
      CONSUMER_PREQUAL_GET,
      (payload) => {
        payload.requestId = requestId;
        if (!payload.prequalStatus) {
          payload.prequalStatus = 'NOT_FOUND'
        }
      },
      false,
      requestId
  );
}

export const prequalGet = (applicationId, requestId, pinRequired) => async (dispatch) => {
  let path = `/prequals/${applicationId}`;
  if (pinRequired) {
    path = path + "?pinRequired=true"
  }
  if (!applicationId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Prequal application ID not specified.",
    });
    return;
  }
  apiInvoke(
      "get",
      path,
      {},
      dispatch,
      CONSUMER_PREQUAL_GET,
      (payload) => {
        payload.requestId = requestId;
      },
      false,
      requestId
  );
}

export const submitData = (loanAppId, payload, requestId) => (dispatch) => {
  // payload.consumerId = loanAppId;
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified",
    });
    return;
  }
  const authPin = payload.authPin
  dispatch({ type: CONSUMER_SUBMIT_DATA, payload });
  dispatch({ type: EXPECTED_RUN_LENGTH, expectedRunLength:'long'});
  // convert fields here to back-end format
  if (payload.annualIncomeBeforeTaxes)
    payload.annualIncomeBeforeTaxes = Number(
      payload.annualIncomeBeforeTaxes.replace(/[^0-9.]/g, "")
    );
  if (payload.reviewed) {
    payload.termsOfServiceAccepted = true;
    payload.electronicDisclosuresAccepted = true;
    payload.privacyPolicyAccepted = true;
  }
  delete payload.reviewed;
  // payload.escalateAuthentication = true;
  apiInvoke(
    "patch",
    `/loanapplications/${loanAppId}`,
    payload,
    dispatch,
    CONSUMER_UPDATE_LOAN,
    (payload) => {
      payload.loanAppId = loanAppId;
      payload.submitDataRequestId = requestId;
      payload.authPin = authPin;
    }, false, requestId
  );
};

export const getOffers = (loanAppId) => (dispatch) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified to get offers",
    });
    return;
  }
  apiInvoke(
      "get",
      `/loanapplications/${loanAppId}/offers`,
      {},
      dispatch,
      CONSUMER_GET_OFFERS
  );
};

export const getCaptureContext = () => (dispatch) => {
  apiInvoke(
      "get",
      `/cards`,
      {},
      dispatch,
      CONSUMER_GET_CAPTURE_CONTEXT
  );
};

export const submitCardData = (loanId, jwt, zipCode) => (dispatch) => {
  if (!loanId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified",
    });
    return;
  }
  if (!jwt) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "JWT not specified",
    });
    return;
  }
  if (!zipCode) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Zip code not specified",
    });
    return;
  }
  apiInvoke(
      "post",
      `/cards`,
      {
        loanId: loanId,
        jwt: jwt,
        zipCode: zipCode
      },
      dispatch,
      CONSUMER_SUBMIT_CARD_DATA,
      payload => {
        payload.loanId = loanId;
        payload.jwt = jwt;
        payload.zipCode = zipCode;
      }
  );
}

export const getDocumentsContent = (loanAppId, entityId, documentNames, requestId) => (dispatch) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified",
    });
    return;
  }
  if (!entityId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Entity ID not specified to get documents content.",
    });
    return;
  }
  if (!requestId) {
    requestId = uuid.v4();
  }
  apiInvoke(
      "post",
      `/loanapplications/${loanAppId}/documentscontent`,
      {entityId, documentNames},
      dispatch,
      CONSUMER_GET_DOCUMENTS_CONTENT,
      payload => {
        payload.requestId = requestId;
      }
  );
}

export const lockOffer = (loanAppId, offerId, requestId) => (dispatch) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application ID not specified to lock offer",
    });
    return;
  }
  if (!offerId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Offer ID not specified to lock offer",
    });
    return;
  }
  if (!requestId) {
    requestId = uuid.v4();
  }
  apiInvoke(
      "post",
      `/loanapplications/${loanAppId}/offers/${offerId}/lock`,
      {},
      dispatch,
      CONSUMER_LOCK_OFFER,
      payload => {
        payload.lockRequestId = requestId;
      }
  );
}

const updateOfferStatus = (
  loanAppId,
  offerId,
  ssn4,
  status,
  provideTruthInLendingDocument,
  type,
  dispatch,
  reviewed,
  payoutId,
  requestId
) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified",
    });
    return;
  }
  if (!offerId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "offer not specified",
    });
    return;
  }
  dispatch({ type: EXPECTED_RUN_LENGTH, expectedRunLength:'short'});

  let data = { status, provideTruthInLendingDocument };
  if (ssn4) {
    data.ssn4 = ssn4
  }
  if (payoutId) {
    data.payoutId = payoutId
  }
  if (reviewed) {
    data.truthInLendingDisclosureAccepted = true;
    data.loanAgreementAccepted = true;
    data.creditScoreDisclosureAccepted = true;
  }

  apiInvoke(
    "patch",
    `/loanapplications/${loanAppId}/offers/${offerId}`,
    data,
    dispatch,
    type,
    (payload) => {
      payload.loanAppId = loanAppId;
      payload.offerId = offerId;
      payload.payoutId = payoutId;
      payload.requestId = requestId;
    },
      false,
      requestId
  );
};

export const acceptOffer = (loanAppId, offerId) => (dispatch) => {
  updateOfferStatus(
    loanAppId,
    offerId,
    null,
    "SELECTED",
    false,
    CONSUMER_ACCEPT_OFFER,
    dispatch
  );
};

export const acceptTruthInLending = (loanAppId, offerId, ssn4, reviewed, payoutId, requestId) => (
  dispatch
) => {
  updateOfferStatus(
    loanAppId,
    offerId,
    ssn4,
    "TILA_ACCEPTED",
    false,
    CONSUMER_ACCEPT_TRUTH_IN_LENDING,
    dispatch,
    reviewed, payoutId, requestId
  );
};

export const getTruthInLending = (
  loanAppId,
  offerId,
  provideTruthInLendingDocument,
  payoutId
) => (dispatch) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified to get truth in lending",
    });
    return;
  }
  if (!offerId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "offer not specified to get truth in lending",
    });
    return;
  }
  let url = `/loanapplications/${loanAppId}/offers/${offerId}`;
  if (provideTruthInLendingDocument) {
    url = url + "?provideTruthInLendingDocument=true";
  }
  apiInvoke(
    "get",
    url,
    {},
    dispatch,
    CONSUMER_GET_TRUTH_IN_LENDING,
    (payload) => {
      payload.loanAppId = loanAppId;
      payload.offerId = offerId;
    }
  );
};

export const acceptPlaidToken = (
  loanAppId,
  offerId,
  token,
  accounts,
  institution,
  autoPaymentsDecision,
  acceptPlaidTokenRequestId
) => (dispatch) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified to link bank",
    });
    return;
  }
  let data = {}
  if (token) {
    data.plaidToken = token;
  }
  if (offerId) {
    data.offerId = offerId;
  }
  if (accounts) {
    data.accounts = accounts;
  }
  if (institution) {
    data.institution = institution;
  }
  if (autoPaymentsDecision) {
    data.autoPaymentsDecision = autoPaymentsDecision;
  }
  apiInvoke(
    "post",
    `/loanapplications/${loanAppId}/paymentaccounts`,
    data,
    dispatch,
    CONSUMER_ACCEPT_PLAID_TOKEN,
    (payload) => {
      payload.acceptPlaidTokenRequestId = acceptPlaidTokenRequestId;
    }
  );
};

export const submitPin = (token, pin) => (dispatch) => {
  apiInvoke(
    "patch",
    `/loanapplications/${token}`,
    { status: "pinRequired", pin },
    dispatch,
    CONSUMER_SUBMIT_PIN
  );
};

export const getPersonaInquiries = (loanApplicationId) => (dispatch) => {
  apiInvoke(
      "get",
      `/loanapplications/${loanApplicationId}/persona-inquiries?mostRecentActive=true`,
      {},
      dispatch,
      CONSUMER_GET_PERSONA_INQUIRIES
  );
};

export const submitPhone = (token, phone, mode, requestId) => (dispatch) => {
  let data = { escalateAuthentication: true };
  if (mode === "returnLogin") {
    data.returnMobileNumber = phone;
  } else {
    data.mobileNumber = phone;
  }
  apiInvoke(
    "patch",
    `/loanapplications/${token}`,
    data,
    dispatch,
    CONSUMER_SUBMIT_PHONE,
    (payload) => {
      payload.mobileNumber = phone;
      payload.submitPhoneRequestId = requestId;
    }
  );
};

export const submitCheckboxSelection = (loanID, page, selected, selectionData, requestId) => (dispatch) => {
  const data = {
    loanID,
    checkboxOrigin: page,
    selectionType: selected ? "CHECKED" : "UNCHECKED",
    timestamp: new Date().toISOString(),
    selectionData,
  }
  apiInvoke(
      "post",
      `/checkbox`,
      data,
      dispatch,
      CONSUMER_CHECKBOX_SELECTION,
      (payload) => {
        payload.selected = selected
        payload.page = page
        payload.requestId = requestId
      },
      !requestId,
      requestId
  );

}

export const sendDocumentCopy = async (
  loanID,
  docID,
  emailAddress,
  callbackOK,
  callbackErr
) => {
  try {
    await axios({
      method: "post",
      url: `${window._wtenv_?.REACT_APP_API_URL}/loanapplications/${loanID}/documents/${docID}/delivery`,
      data: {
        recipient: emailAddress,
      },
    }).then(function (response) {
      callbackOK();
    });
  } catch (err) {
    callbackErr();
  }
};

export const getTila = async (loanAppId, offerId, payoutId) => {
  let url = `${window._wtenv_?.REACT_APP_API_URL}/loanapplications/${loanAppId}/offers/${offerId}?provideTruthInLendingDocument=true`;
  if (payoutId) {
    url = url + `&payoutId=${payoutId}`
  }
  return await axios({
    method: "get",
    url
  })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

const invokeConfirmPurchase = (
  loanAppId,
  payoutId,
  answer,
  requestId,
  type,
  dispatch
) => {
  if (!loanAppId) {
    dispatch({
      type: CONSUMER_ERROR,
      payload: "Loan application not specified",
    });
    return;
  }

  let data = {
    answer: answer,
  };

  if (payoutId) {
    data.payoutId = payoutId;
  }

  apiInvoke(
    "post",
    `/loanapplications/${loanAppId}/confirmation`,
    data,
    dispatch,
    type,
    (payload) => {
      payload.confirmationRequestId = requestId;
    }, false, requestId
  );
};

export const createLinkToken = (loanApplicationId, requestId) => dispatch => {
  let plaidProducts = ["AUTH"]
  let redirectUri = window.location.origin
  if (redirectUri && redirectUri.startsWith("http:") && !redirectUri.includes('localhost')) {
    // only https allowed for non localhost,
    // we need this for development/test environments, OAuth will not work on these environments
    redirectUri = null;
  }
  apiInvoke(
      "post",
      `/borrowerlinktoken`,
      (!redirectUri ?
          {
            loanApplicationId,
            plaidProducts
          } :
          {
            loanApplicationId,
            plaidProducts,
            redirectUri
          }
      ),
      dispatch,
      CONSUMER_CREATE_LINK_TOKEN,
      (payload) => {
        payload.linkToken.requestId = requestId;
        payload.loanApplicationId = loanApplicationId;
      }
  );
}

export const confirmPurchase = (loanAppId, payoutId, answer, requestId) => (dispatch) => {
  invokeConfirmPurchase(
    loanAppId,
    payoutId,
    answer,
    requestId,
    CONSUMER_PURCHASE_RECEIVED,
    dispatch
  );
};

function getLoan(token) {
  return (dispatch) => {
    dispatch({ type: API_REQUEST_STARTED });
    return axios({
      method: "get",
      url: `${window._wtenv_?.REACT_APP_API_URL}/loanapplications/${token}?initToken=${token}`,
    }).then(
      (response) => {
        if (response.headers && response.headers['x-wisetack-token']) {
          const token = response.headers['x-wisetack-token'];
          sessionStorage.setItem("wisetack:ba:token", token);
        }
        dispatch({ type: CONSUMER_GET_LOAN_STATUS, payload: response.data });
      },
      (error) => {
        dispatch({ type: CONSUMER_ERROR, payload: JSON.parse(error.message) });
        throw error;
      }
    );
  };
}

function getOffer(loanAppId, offerId) {
  return (dispatch) => {
    if (!offerId) {
      dispatch({
        type: CONSUMER_ERROR,
        payload: "offer ID not specified to get offer",
      });
      return;
    }
    return axios({
      method: "get",
      url: `${window._wtenv_?.REACT_APP_API_URL}/loanapplications/${loanAppId}/offers/${offerId}`,
    }).then(
      (response) => {
        dispatch({ type: CONSUMER_OFFER_SELECTED, payload: response.data });
        dispatch({ type: API_REQUEST_ENDED });
      },
      (error) => {
        dispatch({ type: CONSUMER_ERROR, payload: JSON.parse(error.message) });
        dispatch({ type: API_REQUEST_ENDED });
        throw error;
      }
    );
  };
}

export function getLoanAndOffer(token) {
  return (dispatch, getState) => {
    return dispatch(getLoan(token)).then(() => {
      let loanAppId = getState().consumer.loanAppId;
      let offerId = getState().consumer.selectedLoanOfferId;
      return dispatch(getOffer(loanAppId, offerId));
    });
  };
}

export const getZip = async (zip) => {
  return await axios({
    method: "post",
    url: `${window._wtenv_?.REACT_APP_API_URL}/zip`,
    data: {
      zip: zip,
    },
  }).then((response) => {
    return response.data;
  });
};

export const sessionExpired = () => async (dispatch, getState) => {
  const loanAppId = getState().consumer.loanAppId;
  console.log(`Loan application [${loanAppId}] session expired action started.`);
  if (loanAppId) {
    await apiInvoke(
        "patch",
        `/loanapplications/${loanAppId}`,
        {sessionExpired: true},
        dispatch,
        CONSUMER_ESCALATE_AUTH,
    );
  }
  dispatch({ type: CONSUMER_SESSION_EXPIRED });
}

export const setError = (payload) => dispatch => {
  dispatch({ type: CONSUMER_ERROR, payload });
}
