import React from "react";
import classNames from "classnames";

import styles from "./PrequalInfoPageBottom.module.scss";

export function PrequalInfoPageBottom({btnDisabled, onButtonClick, onEnterPress, display, disableLast}) {
    if (!display) {
        return null;
    }
    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });
    return <>
        <div className="row">
            <div className="col-1">
                <div className={styles.score}>
                  <span className={classNames("material-icons", styles.scoreIcon)}>
                    lock_outline
                  </span>
                </div>
            </div>
            <div className="col">
                <div className={styles.score}>
                  <span className={styles.scoreTxt}>
                    Continuing <b>will not</b> impact your credit&nbsp;score.
                  </span>
                </div>
            </div>
        </div>
        <div
            className="row"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
            <div className="col">
                <button
                    type="submit"
                    data-test-id="continue"
                    className={btnClasses}
                    onClick={onButtonClick}
                    onKeyPress={(e) => {e.preventDefault(); if(onEnterPress) onEnterPress(e)}}
                >
                    CONTINUE
                </button>
            </div>
        </div>
        {
            !disableLast &&
            <div style={{ margin: "20px", textAlign: "center" }}>
              <span style={{ fontSize: "10px" }}>
                  Wisetack loans are made by Hatch Bank.
              </span>
            </div>
        }
    </>
}
