import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import plane from "@wisetack/assets/img/paper-plane.svg";
import styles from "./ConsumerConfirmationPage.module.scss";

class ConsumerCanceledPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Declined Page (due to Pre-activated Merchant)", {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            cancelReason: this.props.cancelReason
        });
    }

    render() {

        if (this.props.cancelReason === "MERCHANT_DECLINED") {
            return (
                <Container>
                    <PageHeader progress="80%">
                        <Fragment>
                            <strong>Unable to complete your loan</strong>
                        </Fragment>
                        <Fragment/>
                    </PageHeader>
                    <div className={styles.content}>
                        <p>
                            Unfortunately, {this.props.merchantName} can't accept Wisetack financing for this job.
                        </p>
                        <br/>
                        <p>
                            Your loan application is canceled and your credit score will not be impacted.
                        </p>
                        <p>
                            Please work with the merchant to pay by another method.
                        </p>
                        <div style={{ textAlign: "center", paddingTop: "5px" }}>
                            <img className={styles.image} src={plane} alt="paper-plane"/>
                        </div>
                    </div>
                </Container>
            );
        }

        if (this.props.cancelReason === "MERCHANT_OPT_OUT") {
            return (
                <Container>
                    <PageHeader progress="80%">
                        <Fragment>
                            <strong>Unable to complete your loan</strong>
                        </Fragment>
                        <Fragment/>
                    </PageHeader>
                    <div className={styles.content}>
                        <p>
                            Unfortunately, {this.props.merchantName} didn't accept Wisetack financing for this job.
                        </p>
                        <br/>
                        <p>
                            Your loan application is canceled and your credit score will not be impacted.
                        </p>
                        <p>
                            Please work with the merchant to pay by another method.
                        </p>
                        <div style={{ textAlign: "center", paddingTop: "5px" }}>
                            <img className={styles.image} src={plane} alt="paper-plane"/>
                        </div>
                    </div>
                </Container>
            );
        }

        return (
            <Container>
                <PageHeader progress="80%">
                    <Fragment>
                        <strong>Unable to complete your loan</strong>
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <div className={styles.content}>
                    <p>
                        Your loan application for ${this.props.transactionAmount} has been canceled. We have notified {this.props.merchantName} and sent you an email with details
                    </p>
                    <p>
                        If you wish to reapply, please contact {this.props.merchantName}.
                    </p>
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                        <img className={styles.image} src={plane} alt="paper-plane"/> 
                    </div>
                </div>
            </Container>
          );
    }
}

const mapStateToProps = state => ({
    cancelReason: state.consumer.cancelReasonsList ? state.consumer.cancelReasonsList[0] : null,
    loanAppId: state.consumer.loanAppId,
    merchantName: state.consumer.merchantName,
    status: state.consumer.status,
    transactionAmount: state.consumer.transactionAmount,
    firstName: state.consumer.firstName,
    selectedPlan: state.consumer.selectedPlan,
    serviceCompletionDate: state.consumer.serviceCompletionDate
});
  
export default connect(mapStateToProps, {})(ConsumerCanceledPage);
