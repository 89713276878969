import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {loadPrequalAppId, loadToken} from "../utils/localStorage";
import {CONSUMER_ERROR} from "../store/actions/types";
import { logAmplitudeEvent, setAmplitudeUserProperty } from "@wisetack/shared-ui/components/Amplitude";

function pathBasedOnRequiredFields(fieldsRequired) {
    if (fieldsRequired.phone) {
        return "/prequal_phone";
    }
    if (fieldsRequired.pin) {
        return "/prequal_pin";
    }
    if (fieldsRequired.firstName || fieldsRequired.lastName || fieldsRequired.email || fieldsRequired.dob ||
        fieldsRequired.annualIncomeBeforeTaxes || fieldsRequired.ssn4) {
        return "/prequal_entry";
    }
    if (fieldsRequired.city || fieldsRequired.zip || fieldsRequired.streetAddress1) {
        return "/prequal_address";
    }
    if (fieldsRequired.unfreeze) {
        return "/prequal_frozen";
    }
}

export default function usePrequalFlow(prequalGet, newRequest, stayOnCurrentPage, pinRequired) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()

    const signupId = useSelector(state => state.consumer.signupId);
    const prequalId = useSelector(state => state.consumer.prequalId);
    const status = useSelector(state => state.consumer.status);
    const prequalStatus = useSelector(state => state.consumer.prequalStatus);

    let applicationId = useSelector(state => state.consumer.prequalApplicationId);

    const fieldsRequired = useSelector(state => state.consumer.fieldsRequired);

    const [tokenNotFound, setTokenNotFound] = useState(false);

    if (!applicationId) {
        applicationId = loadPrequalAppId();
    }

    const logAmplitude = (page, eventType, eventProperties) => {
        let evProps = {
            page,
            signupId,
            prequalId,
            applicationId,
            prequalStatus,
            path: location.pathname
        }
        if (eventProperties) {
            evProps = {
                ...evProps,
                ...eventProperties
            }
        }
        if (!eventType) {
            eventType = page
        }
        logAmplitudeEvent(eventType, evProps);
    }

    useEffect(() => {
        if (prequalId) {
            setAmplitudeUserProperty('prequalId', prequalId)
        }
    }, [prequalId])

    useEffect(() => {
        if(prequalGet && (!signupId || !prequalId)) {
            const token = loadToken();
            if (!token) {
                setTokenNotFound(true);
                return
            }
            if (applicationId) {
                prequalGet(applicationId, newRequest(), pinRequired)
            } else if (prequalStatus !== 'CANCELED') {
                dispatch({ type: CONSUMER_ERROR, payload: "Please use prequal link to continue application."});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signupId, prequalId, applicationId]);

    let path = pathBasedOnRequiredFields(fieldsRequired);
    if (!path) {
        if (prequalStatus === "CANCELED") {
            path = "/prequal_canceled"
        }
        else if (status === "EXPIRED") {
            path = "/prequal_offers"
        } else if (status === "OFFER_AVAILABLE" || status === "CONDITIONAL_APPROVAL") {
            path = "/prequal_offers"
        } else if (status === "PENDING") {
            path = "/prequal_entry"
        } else if (status === "DECLINED" || status === "REJECTED") {
            path = "/prequal_declined"
        }
    }
    useEffect(() => {
        if (tokenNotFound) {
            logAmplitude("Session Expired", "Token Not Found")
            history.replace("/session_absolute_expiration?prequal=true");
            return;
        }
        if (!stayOnCurrentPage && path && location.pathname !== path) {
            history.push(path);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, location, tokenNotFound]);

    return {signupId, prequalId, applicationId, logAmplitude}
}