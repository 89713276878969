import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Loader from "@wisetack/shared-ui/components/PulseLoader";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerPurchaseCompletePage.module.scss";
import image1 from "@wisetack/assets/img/check-mark.svg";
import image2 from "@wisetack/assets/img/exclamation-mark.svg";
import moment from "moment";
import {getOffers} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import {getVerticalFragment} from "../utils/verticalMessages";
import classNames from "classnames";
import {formatDate} from "../utils/format";

const pageName = "Purchase Complete Page"

class ConsumerPurchaseCompletePage extends React.Component {

  state = {}

  componentDidMount() {
    if (this.navigateToPayouts()) {
      return;
    }

    window.scrollTo(0, 0);

    logAmplitudeEvent(pageName, {
      loanId: this.props.loanAppId.substr(0, 8),
      merchantName: this.props.merchantName,
      page: pageName
    });

    if ((!this.props.selectedPlan || Object.keys(this.props.selectedPlan).length === 0) && this.props.loanAppId) {
      this.props.getOffers(this.props.loanAppId)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initExpired) {
      Log.info(this.props.initExpired, `initExpired`);
      this.props.history.push("/expired");
    }
    if (this.props.errorMessage) {
      Log.info(this.props.errorMessage, `error`);
      this.props.history.push("/error");
    }
    this.navigateToPayouts();
  }

  isPayoutsListFound() {
    return this.props.payouts && this.props.payouts.length > 1
  }

  navigateToPayouts() {
    if (!this.isPayoutsListFound()) {
      return false;
    }
    for (const payout of this.props.payouts) {
      if (payout.status !== "OFFER_CONVERTED" && payout.status !== "SETTLED") {
        this.props.history.push("/loan_payouts");
        return true;
      }
    }
    return false;
  }

  handleOnBack = () => {
    this.props.history.push("/loan_payouts");
  }

  render() {
    const {
      selectedPlan,
      isLoading,
      errorMessage,
      merchantName,
      autoPayments,
      loanServicer,
      vertical,
      settlementDelay,
      settlementDate,
      firstMonthPrepayment,
      cardLastFourDigits
    } = this.props;
    Log.info(this.props, `ConsumerPurchaseCompletePage props`);
    let date = "";

    const plan = selectedPlan || {};
    if (plan.starting) {
      const starting = moment(plan.starting, "MM/DD/YYYY");
      if (starting.isValid()) {
        date = starting.format("LL");
      }
    }

    const LoaderWithMessage = () => {
      if (!isLoading) return null;
      return (
        <div>
          <Loader/>
          <div className={styles.message}>Wait a moment please...</div>
        </div>
      );
    };

    const bullet_point_1 = (merchantName, vertical, settlementDate, settlementDelay) => {

      if (vertical === 'LG' && settlementDelay) {
        return <p>
          • <b>{merchantName}</b> will receive the funds on <span style={{whiteSpace: 'nowrap'}}><b>{formatDate(settlementDate, 'MM-DD-YYYY', 'MMM DD, YYYY')}</b></span>
        </p>
      }

      return <p>
        • <b>{merchantName}</b> will receive the funds.<br/>
      </p>
    }

    const bullet_point_2 = (settlementDelay) => {
      if (settlementDelay) {
        return <p>
          • Interest will not accrue until we send the funds
        </p>
      }
    }

    const bullet_point_3 = (loanServicer) => {
      return <p>
        {loanServicer === 'Peach' ?
            <>• You'll get an email from us to manage your loan payments.</> :
            <>• You'll receive an email from <b>{loanServicer}</b>, where you can manage your loan and make payments.</>
        }
        <span className={styles.learnMore}
            data-toggle="modal"
            data-target="#learnMoreModal"
            onClick={() => {
              logAmplitudeEvent("Opened Learn more link", this.logProps);
            }}
        >
                    &nbsp;<b>Learn more.</b>
                    </span><br/>
      </p>
    }

    const bullet_point_4 = (date, settlementDelay, firstMonthPrepayment, cardLastFourDigits) => {
      if (firstMonthPrepayment) {
        return <p>
          • Your first installment is charged on <b>{date}</b>.<br/>We will use the card on file ending in *{cardLastFourDigits}.
        </p>
      } else {
        if (settlementDelay) {
          return <p>
            • Your first payment will be due on <b>{date}</b>.
          </p>
        }

        return <p>
          • Your first payment is due on <b>{date}</b>.
        </p>
      }
    }

    const showContent = !isLoading && !errorMessage;

    return (
      <Container>
        <LearnMoreModal loanServicer={loanServicer}/>
        <PageHeader progress="100%">
          {showContent ? <Fragment>Hooray! Loan Confirmed!</Fragment> : null}
          <Fragment/>
        </PageHeader>
        <LoaderWithMessage/>
        <Error pageName={pageName}>{errorMessage}</Error>
        {showContent ? (
          <>
            <img className={styles.image} src={image1} alt="check-mark"/>
            <div className="row" style={{marginBottom: "5px"}}>
              <div className="col">
                <div className={styles.next}>
                  <h5>What happens next?</h5>
                  <div className={styles.innerText}>
                    {bullet_point_1(merchantName, vertical, settlementDate, settlementDelay)}
                    {bullet_point_2(settlementDelay)}
                    {bullet_point_3(loanServicer)}
                    {bullet_point_4(date, settlementDelay, firstMonthPrepayment, cardLastFourDigits)}
                  </div>
                  {autoPayments === 'ENABLED' &&
                  <div className={styles.autoPaymentsMessage}>
                    <span className="material-icons" style={{ fontSize: "22px", verticalAlign: "bottom"}}>check_circle_outline</span>
                    <b> You've enabled automatic payments</b>
                  </div>
                  }
                  { this.isPayoutsListFound() &&
                    <button
                        data-test-id="purchase-complete-back"
                        className={classNames("btn", styles.buttonEnabled)}
                        onClick={this.handleOnBack}
                    >
                      BACK TO LOAN PAYOUTS
                    </button>
                  }
                  <div className={styles.support}>
                    <img className={styles.image} src={image2} alt="exclamation-mark"/>
                    <h5>{getVerticalFragment('purchase_complete_header', this.props.vertical)}</h5>
                    <div>
                      {getVerticalFragment('purchase_complete_message', this.props.vertical, {merchantName})}
                      <a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div style={{paddingBottom: "15px"}}/>
        )}
      </Container>
    );
  }
}

ConsumerPurchaseCompletePage.propTypes = {
  selectedPlan: PropTypes.object.isRequired,
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  selectedPlan: state.consumer.selectedPlan,
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  offerId: state.consumer.selectedLoanOfferId || state.consumer.offerId,
  isLoading: state.consumer.isLoading,
  errorMessage: state.consumer.errorMessage,
  initExpired: state.consumer.initExpired,
  autoPayments: state.consumer.autoPaymentsDecision,
  payouts: state.consumer.payouts,
  loanServicer: state.consumer.loanServicer,
  vertical: state.consumer.vertical,
  settlementDelay: state.consumer.settlementDelay,
  settlementDate: state.consumer.settlementDate,
  firstMonthPrepayment: state.consumer.firstMonthPrepayment,
  cardLastFourDigits: state.consumer.cardLastFourDigits
});

export default connect(
  mapStateToProps,
  {getOffers}
)(ConsumerPurchaseCompletePage);
