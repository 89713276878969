import React from "react";
export default function simulateMouseClick(element) {
  if (!element) {
    return;
  }

  var mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  mouseClickEvents.forEach(function (mouseEventType) {
    return element.dispatchEvent(new MouseEvent(mouseEventType, {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1
    }));
  });
}