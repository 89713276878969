import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import image from "@wisetack/assets/img/paper-plane.svg";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";

import {prequalGet} from "../store/actions/consumerActions";
import usePrequalFlow from "../hooks/usePrequalFlow";
import styles from "./PrequalCanceledPage.module.scss";

const pageName = 'Prequal Canceled Page'

function PrequalCanceledPage({prequalGet}) {
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const merchantName = useSelector(state => state.consumer.merchantName) || 'merchant';

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const {logAmplitude} = usePrequalFlow(prequalGet, newRequest, true);

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    return <Container>
        <PageHeader progress="100%">
            <div>Unable to complete your prequalification</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="notified-logo"/>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        {
            !requestInProgress &&
            <>
                <div className={styles.message}>
                    Your prequalification for {merchantName} has been canceled.
                </div>
                <div className={styles.message}>
                    If you wish to reapply, please contact {merchantName}.
                </div>
                <Error pageName={pageName}>{requestError || errorMessage}</Error>
            </>
        }
    </Container>
}

export default connect(null, {prequalGet})(
    PrequalCanceledPage
);

