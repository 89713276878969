import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./ConsumerErrorPage.module.scss";
import image from "@wisetack/assets/img/fail-ice-cream.svg";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

class ConsumerErrorPage extends React.Component {
  componentDidMount() {
    logAmplitudeEvent("System Error Page", {
      loanId: this.props.loanAppId ? this.props.loanAppId.substr(0, 8) : "unknown",
      merchantId: this.props.merchantId,
      status: this.props.status,
      errorMessage: this.props.errorMessage
    });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // add logic here to route to another page depending from status
  }

  handledErrorMessage(unhandledErrorMessage) {
    // Check whether we can display a more user-friendly version of the error
    if (!unhandledErrorMessage) {
      return { errorHandled: false, errorMessage: "" }
    }
    const thirdPartyCookiesBlocked = // Error message will depend on browser
        unhandledErrorMessage.toLowerCase().includes("access is denied for this document")  // Chrome
        || unhandledErrorMessage.toLowerCase().includes("the operation is insecure");       // Firefox, Safari

    if (thirdPartyCookiesBlocked) {
      return {
        errorHandled: true,
        errorMessage: "Sorry, something went wrong! Please unblock third-party cookies on your browser and try again."
      }
    }
    return { errorHandled: false, errorMessage: unhandledErrorMessage }
  }

  render() {
    Log.info(this.props, `ConsumerErrorPage props`);
    const { errorHandled, errorMessage } = this.handledErrorMessage(this.props.errorMessage);
    if (errorHandled) {
      return (
          <Container>
            <PageHeader progress="">
              <div>{ errorMessage }</div>
              <div/>
            </PageHeader>
            <img className={styles.image} src={image} alt="error-logo" />
          </Container>
      );
    } else {
      return (
          <Container>
            <PageHeader progress="">
              <div>Sorry, something went wrong! Please try again later.</div>
              <div style={{color: "red"}}>{ errorMessage }</div>
            </PageHeader>
            <img className={styles.image} src={image} alt="error-logo" />
          </Container>
      );
    }
  }
}

ConsumerErrorPage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

const mapStateToProps = state => ({
  loanAppId: state.consumer.loanAppId,
  merchantName: state.consumer.merchantName,
  status: state.consumer.status,
  errorMessage: state.consumer.errorMessage
});

export default connect(
  mapStateToProps,
  {}
)(ConsumerErrorPage);
