import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import PinForm from "@wisetack/shared-ui/components/PinForm";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Error from "@wisetack/shared-ui/components/Error";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import {prequalSubmit, prequalGet, setError} from "../store/actions/consumerActions";

import styles from "./PrequalPinPage.module.scss";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";
import usePrequalFlow from "../hooks/usePrequalFlow";
import {CONSUMER_PREQUAL_PHONE} from "../store/actions/types";

const pageName = 'Prequal Pin Page';

function PrequalPinPage({prequalSubmit, prequalGet}) {
    const dispatch = useDispatch()

    const mobileNumber = useSelector(state => state.consumer.mobileNumber);
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const fieldsRequired = useSelector(state => state.consumer.fieldsRequired);
    const prequalSubmitRequestId = useSelector(state => state.consumer.prequalSubmitRequestId);

    const [pin, setPin] = React.useState(null);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const invalidPin = !!requestId && !!prequalSubmitRequestId && requestId === prequalSubmitRequestId && fieldsRequired.pin

    const {signupId, prequalId, logAmplitude} = usePrequalFlow(prequalGet, newRequest, false, true);

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (invalidPin) {
            logAmplitude(pageName, 'Error', {message: "Enter a valid verification code"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invalidPin]);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    const handleOnChange = e => {
        let val = e.target.value;
        val = val.replace(/\D/g, "");
        if (val.length <= 4) {
            setPin(val);
        }
        if (val.length === 4) {
            handlePinSubmit(val)
        }
    }

    const handleOnRetryButtonClick = () => {
        dispatch({ type: CONSUMER_PREQUAL_PHONE })
    }

    const formatMobileNumber = (mobileNumber) => {
        if (mobileNumber) {
            mobileNumber = formatUsPhone(mobileNumber);
            if (mobileNumber.startsWith('1-')) {
                mobileNumber = mobileNumber.substr(2);
            }
            return mobileNumber;
        }
    }

    const handlePinSubmit = (authPin) => {
        if (signupId && prequalId && authPin) {
            prequalSubmit(
                signupId,
                prequalId,
                {
                    authPin,
                    mobileNumber
                },
                newRequest()
            );
            logAmplitude(pageName, 'Pin submitted')
        }
    }

    return <Container>
        <PageHeader progress="0.3%">
            <div>We just texted you</div>
            <div style={{ paddingLeft: "15px", textAlign: "center" }}>
                Please enter the verification code we&nbsp;sent&nbsp;to
                <br />
                <b>{formatMobileNumber(mobileNumber)}</b>
            </div>
        </PageHeader>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
        <div style={{ paddingBottom: "25px" }}>
            {!requestInProgress && (
                <>
                    <PinForm>
                        <FormRow>
                            <FormInput
                                autoFocus
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                name="pin"
                                label="Enter 4-digit code"
                                value={pin}
                                onChange={handleOnChange}
                                suggestions={invalidPin ? {"pin": "Enter a valid verification code"} : {}}
                            />
                        </FormRow>
                    </PinForm>
                    <div className="row">
                        <div className="col">
                            <div
                                className={styles.buttonLink}
                                onClick={handleOnRetryButtonClick}
                            >
                                Didn't get a code?
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    </Container>
}

export default connect(null, {prequalSubmit, prequalGet, setError})(
    PrequalPinPage
);
