import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";
import classNames from "classnames";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";

import styles from "./LoanPayoutAcceptPage.module.scss";
import {PayoutReview} from "../components/PayoutReview";
import {useToggle} from "../hooks/useToggle";
import {CheckBox} from "../components/CheckBox";
import {Ssn4Form} from "../components/Ssn4Form";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import {
    submitCheckboxSelection,
    acceptTruthInLending, getTila, sendDocumentCopy
} from "../store/actions/consumerActions";
import Error from "@wisetack/shared-ui/components/Error";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import usePayoutFlow from "../hooks/usePayoutFlow";
import LoanAgreementModal from "@wisetack/shared-ui/components/LoanAgreementModal";
import CreditScoreModal from "@wisetack/shared-ui/components/CreditScoreModal";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";
import usePayoutLog from "../hooks/usePayoutLog";

const pageName = "Loan Payout Accept Page"

function LoanPayoutAcceptPage({submitCheckboxSelection, acceptTruthInLending}) {
    const history = useHistory();

    const selectedPlan = useSelector(state => state.consumer.selectedPlan);
    const selectedPayout = useSelector(state => state.consumer.selectedPayout);
    const loanAppId = useSelector(state => state.consumer.loanAppId);
    const checkboxesRequestId = useSelector(state => state.consumer.checkboxes.requestId);

    const [reviewedRequestId, reviewedRequestInProgress, reviewedRequestError, newReviewedRequest] = useApiRequest('consumer');
    const [acceptRequestId, acceptRequestInProgress, acceptRequestError, newAcceptRequest] = useApiRequest('consumer');

    const [ssn4, setSsn4] = useState("");
    const [errors, setErrors] = useState({});
    const [reviewed, setReviewed] = useToggle();

    const payoutData = usePayoutFlow(selectedPlan, selectedPayout);

    const logAmplitude = usePayoutLog(pageName)

    useEffect(() => {
        if (!!reviewedRequestId && reviewedRequestId === checkboxesRequestId) {
            setReviewed()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkboxesRequestId, reviewedRequestId]);

    const handleOnBack = () => {
        logAmplitude("Back Button clicked")
        history.push("/loan_payouts")
    }

    const handleReviewed = () => {
        logAmplitude("Reviewed CheckBox clicked", {stateBeforeClick: reviewed})
        submitCheckboxSelection(loanAppId, "ALMOST_DONE_PAGE", !reviewed, { loanOfferId: selectedPlan.id, payoutId: selectedPayout.id }, newReviewedRequest())
    }

    const handleOnButtonClick = () => {
        logAmplitude("Accept Button clicked", {selectedPayout, reviewed, ssn4})
        acceptTruthInLending(loanAppId, selectedPlan.id, ssn4, reviewed, selectedPayout.id, newAcceptRequest())
    }

    const loadTila = () => {
        return new Promise((resolve, reject) => {
            if (!loanAppId || !selectedPayout || !selectedPayout.selectedLoanOfferId) {
                reject()
                return;
            }
            logAmplitude("TIL document loading started")
            getTila(loanAppId, selectedPayout.selectedLoanOfferId, selectedPayout.id)
                .then( res => {
                    if (res && res.loanPayoutDetailsList) {
                        logAmplitude("TIL document loaded")
                        resolve(atob(res.loanPayoutDetailsList[0].truthInLending));
                    }
                })
                .catch(err => reject(err));
        });
    }

    let btnDisabled = !reviewed || !ssn4 || reviewedRequestInProgress;

    for (const key in errors) {
        if (errors[key]) btnDisabled = true;
    }

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });

    return <Container>
        { selectedPayout &&
            <PdfModalZoom name="truthInLendingModal" dataLoadFunc={loadTila} sendFunction={sendDocumentCopy}
                          allowCopySend={true} applicationID={loanAppId} documentID={selectedPayout.tilaDocumentId}
                          headerTitle="Truth In Lending" />
        }
        <LoanAgreementModal />
        <CreditScoreModal />
        <PageHeader progress="80%" onBack={handleOnBack}>
            <div>Review your terms</div>
            <div/>
        </PageHeader>
        <PayoutReview payoutData={payoutData}/>
        <div className={styles.approve}>
            <div className="row">
                <div className="col-1" style={{ minWidth: "40px" }}>
                    <CheckBox loading={reviewedRequestInProgress} checked={reviewed} onClick={handleReviewed}/>
                </div>
                <div className={classNames("col", styles.reviewed)}>
                    I have reviewed and agree to the
                    <span
                        data-toggle="modal"
                        data-target="#truthInLendingModal"
                        onClick={() => {
                            logAmplitude("Opened TIL link");
                        }}
                    >
                        &nbsp;Truth in Lending Disclosure
                    </span>
                    &nbsp;and{" "}
                    <span
                        data-toggle="modal"
                        data-target="#loanAgreement"
                        onClick={() => {
                            logAmplitude("Opened Loan agreement link");
                        }}
                    >
                        &nbsp;Loan Agreement
                    </span>
                    , and have received the
                    <span
                        data-toggle="modal"
                        data-target="#creditScoreModal"
                        onClick={() => {
                            logAmplitude("Opened Credit Score disclosure link");
                        }}
                    >
                        &nbsp;Credit Score Disclosure.
                    </span>
                </div>
            </div>
            <Ssn4Form ssn4={ssn4} setSsn4={setSsn4} errors={errors} setErrors={setErrors}/>
        </div>
        <Error pageName={pageName}>{reviewedRequestError || acceptRequestError}</Error>
        { !acceptRequestInProgress &&
            <div className={classNames("row", styles.buttonContainer)}>
                <div className="col">
                    <button
                        data-test-id="confirm"
                        className={btnClasses}
                        onClick={handleOnButtonClick}
                    >
                      <span className={classNames("material-icons", styles.lock)}>
                        lock_outline
                      </span>
                        {"ACCEPT TERMS & SIGN"}
                    </button>
                </div>
            </div>
        }
        <LoaderWithMessage loading={!!acceptRequestId && !!acceptRequestInProgress}/>
        <div className={styles.message}>
            <span>Release funds in the next step</span>
            <span className={classNames("material-icons", styles.next_icon)}>arrow_forward</span>
        </div>
    </Container>
}

export default connect(null, {submitCheckboxSelection, acceptTruthInLending})(
    LoanPayoutAcceptPage
);
