import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Buttons from '@wisetack/shared-ui/components/Buttons'
import Container from "@wisetack/shared-ui/components/Container"
import PageHeader from '@wisetack/shared-ui/components/PageHeader'
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import exclamationMark from "@wisetack/assets/img/exclamation-mark.svg"
import { getLoanStatus } from '../store/actions/consumerActions'
import { loadToken } from '@wisetack/shared-ui/utils/localStorage'

import styles from "./PlaidErrorPage.module.scss"

const pageName = "PlaidErrorPage"

function PlaidError(props) {
    window.console.log("props:", props);
    const logProps = {
        loanId: props.loanAppId.substr(0, 8),
        merchantName: props.merchantName,
        page: pageName
    }

    useEffect(() => {
        if (!props.loanAppId) {
            let token = loadToken();
            props.getLoanStatus(token, null, null, false);
        }
    });

    const handleOnSkipButtonClick = () => {
        logAmplitudeEvent("Pressed Skip Button", logProps);
        if ((props.status === "CONDITIONAL_APPROVAL" || props.status === "CONDITIONAL_APPROVAL_ACCOUNT_LINKED")) {
            if (props.moreInfoRequired) {
                props.history.push("/moreinfo");
                return;
            }
        } else {
            props.history.push("/review_plan");
        }
    };

    const handleOnTryAgainButtonClick = () => {
        logAmplitudeEvent("Pressed Try Again Button", logProps);
        props.history.push("/link_bank");
    }

    return (
        <Container>
            <PageHeader progress="80%">
                <div>Something went wrong</div>
                <img className={styles.image} src={exclamationMark} alt={"exclamation-mark.svg"} />
            </PageHeader>
            <div className={styles.div}>We’re having trouble verifying your bank information. Let’s try again{props.bankVerificationRequired ? "." : " or you can skip to continue."}</div>
            <div className={styles.div}>If you continue to have trouble, contact <b><a href="mailto:support@wisetack.com">support@wisetack.com</a></b> for assistance.</div>
            <Buttons.Primary style={{ marginTop: '30px' }} onClick={handleOnTryAgainButtonClick}>Try Again</Buttons.Primary>
            {props.bankVerificationRequired || <Buttons.Secondary onClick={handleOnSkipButtonClick}>skip</Buttons.Secondary>}
        </Container>
    )
}

const names = ["bankVerificationRequired", "loanAppId", "merchantName", "status", "moreInfoRequired"];

function mapStateToProps(state) {
    let props = {};
    names.forEach(name => {
        props[name] = state.consumer[name];
    });
    return props;
}

export default connect(mapStateToProps, { getLoanStatus })(PlaidError);