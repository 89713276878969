import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {connect, useSelector} from "react-redux";
import {parse} from 'query-string';

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Warning from "@wisetack/shared-ui/components/Warning";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";

import {prequalSubmit, setError, signupPrequalGet} from "../store/actions/consumerActions";

import styles from "./PrequalSignupPage.module.scss";
import usePrequalFlow from "../hooks/usePrequalFlow";
import LearnMorePrequalModal from "./LearnMorePrequalModal";
import {PrequalPhoneForm} from "../components/PrequalPhoneForm";

const pageName = 'Prequal Signup Page'

const SignupPageHeader = ({prequalStatus}) => {
    if (!prequalStatus) {
        return (
            <PageHeader  progress="0.3%">
                <div/>
                <div/>
            </PageHeader>
        )
    }
    if (prequalStatus === 'NOT_FOUND' || prequalStatus === 'EXPIRED') {
        return (
            <>
                <PageHeader  progress="0.3%">
                    <div>
                        Prequalify instantly!
                    </div>
                    <div className={styles.subtitle}>
                        Paying over time is easy with Wisetack.
                        Just enter a few details to check your&nbsp;options.<br/>
                        <span
                            data-toggle="modal"
                            data-target="#learnMorePrequalModal"
                        >
                    Learn More
                </span>
                    </div>
                </PageHeader>
                <div className={styles.info}>
                    <ul>
                        <li>Rates from 0% APR</li>
                        <li>No hidden fees</li>
                        <li>Checking <b>won’t affect your credit&nbsp;score</b></li>
                    </ul>
                </div>
            </>
        )
    }
    return (
        <PageHeader  progress="0.3%">
            <div>
                Welcome back!
            </div>
            <div className={styles.subtitle}>
                Enter the phone number associated with your prequalification. We’ll send you a verification code to&nbsp;continue.
            </div>
        </PageHeader>
    )
}

function PrequalSignupPage({prequalSubmit, signupPrequalGet, setError}) {
    const prequalStatus = useSelector(state => state.consumer.prequalStatus);
    const fieldsValue = useSelector(state => state.consumer.fieldsValue);
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const { signupId, prequalId, checksum } = useParams();
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');

    const merchantProvidedPhone = !!fieldsValue && formatUsPhone(fieldsValue.merchantProvidedPhone);

    const search = parse(window.location.search);

    const {logAmplitude} = usePrequalFlow();

    useEffect(() => {
        logAmplitude(pageName, pageName, {signupId, prequalId});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    useEffect(() => {
        if (signupId && prequalId) {
            signupPrequalGet(signupId, prequalId, newRequest(), true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signupId, prequalId]);

    const handleSubmit = (mobileNumber) => {
        if (!checksum) {
            setError('Invalid link. Checksum not specified.');
            return;
        }
        const data = {
            escalateAuthentication: true,
            mobileNumber,
            checksum
        }
        if (search.decline) {
            data.decline = true;
        }
        if (search.frozen) {
            data.frozen = true;
        }
        if (search.customerId) {
            data.customerId = search.customerId;
        }
        prequalSubmit(
            signupId,
            prequalId,
            data,
            newRequest()
        );
        logAmplitude(pageName, 'Phone number submitted')
    }

    return <Container>
        <SignupPageHeader prequalStatus={prequalStatus}/>
        <Warning message={requestError || errorMessage}/>
        { prequalStatus &&
            <PrequalPhoneForm
                merchantProvidedPhoneNumber={merchantProvidedPhone}
                requestInProgress={requestInProgress}
                onSubmit={handleSubmit}/>
        }
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        <LearnMorePrequalModal/>
    </Container>
}


export default connect(null, {prequalSubmit, setError, signupPrequalGet})(
    PrequalSignupPage
);
