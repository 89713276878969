import moment from "moment";

import {saveLoanAppId, saveSubmitted, saveToken, saveTokenTTL,} from "@wisetack/shared-ui/utils/localStorage";

import {
  APPLICATION_EXPIRED,
  CONSUMER_ACCEPT_OFFER,
  CONSUMER_ACCEPT_PLAID_TOKEN,
  CONSUMER_ACCEPT_TRUTH_IN_LENDING,
  CONSUMER_API_ERROR,
  CONSUMER_AUTOPREQUAL_INIT,
  CONSUMER_CHECKBOX_SELECTION,
  CONSUMER_CLEAR_STATE,
  CONSUMER_CREATE_LINK_TOKEN,
  CONSUMER_ERROR,
  CONSUMER_GET_CAPTURE_CONTEXT,
  CONSUMER_GET_DOCUMENTS_CONTENT,
  CONSUMER_GET_LOAN_STATUS,
  CONSUMER_GET_OFFERS,
  CONSUMER_GET_PERSONA_INQUIRIES,
  CONSUMER_GET_TRUTH_IN_LENDING,
  CONSUMER_LOCK_OFFER,
  CONSUMER_OFFER_SELECTED,
  CONSUMER_PREQUAL_GET,
  CONSUMER_PREQUAL_PATCH,
  CONSUMER_PREQUAL_PHONE,
  CONSUMER_PREQUAL_SUBMIT,
  CONSUMER_PURCHASE_RECEIVED,
  CONSUMER_SELECT_PAYOUT,
  CONSUMER_SESSION_EXPIRED,
  CONSUMER_SUBMIT_CARD_DATA,
  CONSUMER_SUBMIT_DATA,
  CONSUMER_SUBMIT_PHONE,
  CONSUMER_SUBMIT_PIN,
  CONSUMER_UPDATE_LOAN,
  EXPECTED_RUN_LENGTH,
  FIELD_ERROR,
  MERCHANT_CREATE_LOAN,
  SSN4_ERROR
} from "../actions/types";

import {API_REQUEST_ENDED, API_REQUEST_STARTED,} from "@wisetack/shared-ui/utils/Api";
import {savePrequalAppId} from "../../utils/localStorage";
import {CONSUMER_SMARTY_STREETS_AUTOCOMPLETE} from "@wisetack/shared-ui/utils/SmartyStreetsApi";
import {logAmplitudeEvent, setAmplitudeUserId} from "@wisetack/shared-ui/components/Amplitude";
import {formatAmount} from "@wisetack/shared-ui/utils/format";
import { datadogRum } from '@datadog/browser-rum'

const initState = {
  token: "",
  loanAppId: "",
  merchantName: "",
  merchantId: "",
  transactionAmount: null,
  consumerId: "",
  firstName: "",
  lastName: "",
  email: "",
  annualIncomeBeforeTaxes: "",
  zip: "",
  dob: "",
  ssn4: "",
  ssn: "",
  streetAddress1: "",
  city: "",
  stateCode: "",
  employer: "",
  fieldsRequired: {},
  fieldsError: {},
  fieldsValue: {},
  plans: [],
  payouts: [],
  selectedPlan: {},
  status: "",
  statusAt: null,
  statusId: "",
  selectedLoanOfferId: "",
  selectedLoanOfferStatus: "",
  offerAcceptedAt: null,
  truthInLendingAcceptedAt: null,
  isLoading: false,
  errorMessage: "",
  ssn4Error: "",
  initExpired: false,
  adverseAction: null,
  h5CreditScoreDisclosure: null,
  loanAppExpirationDate: null,
  linkTokens: {},
  apiRequests: {},
  apiError: {},
  checkboxes: {}
};

const round = (value, decimals) => {
  if (!value) {
    return value;
  }
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

const convertDocumentsContent = (payload) => {
  const data = {documentsEntityId: payload.entityId}
  if (payload.documents) {
    if (payload.documents['WisetackAdverseActionNotice.pdf']) {
      data.adverseAction = payload.documents['WisetackAdverseActionNotice.pdf']
    }
    if (payload.documents['H5_CREDIT_SCORE_DISCLOSURE_DOCUMENT']) {
      data.h5CreditScoreDisclosure = payload.documents['H5_CREDIT_SCORE_DISCLOSURE_DOCUMENT']
    }
  }
  return data;
}

const convertOfferToPlan = (item) => {
  if (!item) {
    return {}
  }
  return {
    id: item.id,
    status: item.status,
    months: item.loanTermMonths,
    numberOfPayments: item.numberOfPayments,
    totalPayments: round(item.totalPayments, 2),
    apr: item.interestRate,
    // amount: round(item.monthlyAmount + item.monthlyAmount * item.apr / 100 / 12, 2),
    amount: round(item.monthlyPayment, 2),
    // interest: round(item.loanPeriod * item.monthlyAmount * item.apr / 100 / 12, 2),
    interest: round(item.totalInterest, 2),
    // starting: moment(item.firstPaymentDue, 'YYYY-MM-DD').format('MM/DD/YYYY')
    starting: moment.unix(item.firstPaymentDue / 1000).format("MM/DD/YYYY"),
    approvedAmount: item.loanAmount,
    truthInLendingID: item.truthInLendingID,
  };
};

const payoutPath = (payout) => {
  switch (payout.status) {
    case "PENDING":
      return "/choose_plan"
    case "OFFER_AVAILABLE":
    case "COUNTER_OFFER_AVAILABLE":
      return "/loan_payout_accept"
    case "DOCUMENTS_SIGNED":
      if (payout.skipConfirmation) {
        return "/loan_payout_confirm_pending"
      }
      return "/loan_payout_confirm"
    case "OFFER_CONVERTED":
    case "SETTLED":
      return "/loan_payout_complete"
    case "DECLINED":
      if (!!payout.declineReasonsList && payout.declineReasonsList.includes('INELIGIBLE_FOR_ADDITIONAL_LOAN')) {
        return "/declined_max"
      }
      return "/declined"
    default:
      return "/loan_payouts"
  }
}

const convertPayout = (item) => {
  return {
    id: item.id,
    payoutName: item.payoutName,
    approvedLoanAmount: round(item.approvedLoanAmount, 2),
    monthlyPayment: round(item.monthlyPayment, 2),
    ratePercent: item.ratePercent,
    selectedLoanOfferId: item.selectedLoanOfferId,
    termMonths: item.termMonths,
    statusName: item.statusName,
    status: item.status,
    expDate: item.expDate ? new Date(item.expDate) : null,
    starting: item.firstPaymentDue ? moment.unix(item.firstPaymentDue / 1000).format("MM/DD/YYYY") : '',
    path: payoutPath(item),
    serviceCompletionProvidedByBorrower: item.serviceCompletionProvidedByBorrower ? new Date(item.serviceCompletionProvidedByBorrower) : null,
    tilaDocumentId: item.tilaDocumentId,
    disabled: item.disabled
  }
}

const selectedPayout = (item, items) => {
  if (!item) {
    return {}
  }
  const selectedPayout = convertPayout(item)
  const payouts = []
  if (items && items.length > 0) {
    for (const payout of items) {
      if (payout.id === selectedPayout.id) {
        payouts.push(selectedPayout)
      } else {
        payouts.push(payout)
      }
    }
  }
  return {
    selectedPayout,
    payouts
  }
}

const fields = {
  BORROWER_PROVIDED_BORROWER_DOB: "dob",
  BORROWER_PROVIDED_BORROWER_INCOME: "annualIncomeBeforeTaxes",
  BORROWER_PROVIDED_BORROWER_ZIP: "zip",
  BORROWER_PROVIDED_BORROWER_SSN4: "ssn4",
  BORROWER_PROVIDED_BORROWER_FIRST_NAME: "firstName",
  BORROWER_PROVIDED_BORROWER_LAST_NAME: "lastName",
  BORROWER_PROVIDED_BORROWER_EMAIL: "email",
  //
  BORROWER_PROVIDED_BORROWER_SSN: "ssn",
  BORROWER_PROVIDED_BORROWER_ADDRESS: "streetAddress1",
  BORROWER_PROVIDED_BORROWER_ADDRESS2: "addressSecondaryNumber",
  BORROWER_PROVIDED_BORROWER_CITY: "city",
  BORROWER_PROVIDED_BORROWER_STATE: "stateCode",
  BORROWER_PROVIDED_BORROWER_EMPLOYER: "employer",
  BORROWER_PROVIDED_AUTH_PIN: "pin",
  BORROWER_PROVIDED_BORROWER_PHONE: "phone",
  MERCHANT_PROVIDED_BORROWER_PHONE: "merchantProvidedPhone",
  //
  BORROWER_PROVIDED_CREDIT_FILE_UNFREEZE: "unfreeze",
  //
  LINKED_BORROWER_PHONE_VERIFICATION: "linked_phone",
};

const addressFields = [
  "BORROWER_PROVIDED_BORROWER_ADDRESS",
  "BORROWER_PROVIDED_BORROWER_ADDRESS2",
  "BORROWER_PROVIDED_BORROWER_CITY",
  "BORROWER_PROVIDED_BORROWER_STATE",
  "BORROWER_PROVIDED_BORROWER_ZIP",
];

const getFieldsRequired = (array, skipAddress) => {
  if (!array) return {};

  let reqFieldsCount = 0;

  const fieldSet = array.reduce((obj, item) => {
    obj[fields[item.type]] = true;
    if (!skipAddress && !addressFields.includes(item.type)) reqFieldsCount++;
    return obj;
  }, {});

  // Logic to ask address fields on next screen.
  if (reqFieldsCount > 0) {
    fieldSet.streetAddress1 = false;
    fieldSet.addressSecondaryNumber = false;
    fieldSet.city = false;
    fieldSet.zip = false;
    fieldSet.stateCode = false;
  }

  if (fieldSet.stateCode) {
    fieldSet.zip = true;
  }

  if (fieldSet.zip && !fieldSet.city) {
    fieldSet.stateCode = true;
  }

  return fieldSet;
};

const getFieldsError = (array) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
    if (item.errorMessage) {
      obj[fields[item.type]] = item.errorMessage;
    }
    return obj;
  }, {});
};

const getFieldsSource = (array) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
      if(item.source) {
        obj[fields[item.type]] = item.source;
      }
      return obj;
  }, {});
};

const getFieldsValue = (array) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
    if (item.prefilledValue || item.prefilledValue === "") {
      obj[fields[item.type]] = item.prefilledValue;
      if (fields[item.type] && fields[item.type] === "dob") {
        const parts = item.prefilledValue.split("-");
        if (parts.length === 3) {
          obj.year = parts[0];
          obj.month = parts[1];
          obj.day = parts[2];
        }
      }
    }
    return obj;
  }, {});
};

const setFieldsError = (existingErrors, newErrors) => {
  return {
    ...existingErrors,
    ...newErrors,
  };
};

const handleSessionExpiration = () => {
  sessionStorage.clear();
  localStorage.clear();
}

const initSession = (payload) => {
  if (payload.loanApplicationId) {
    const session = sessionStorage.getItem('wisetack:session');
    const sessionData = session ? session.split(':') : null
    if (!sessionData || sessionData[0] !== payload.loanApplicationId) {
      sessionStorage.setItem('wisetack:session', `${payload.loanApplicationId}:${Date.now().toString()}`)
    }
  }
}

const updateSession = (payload) => {
  if (payload.loanAppId && payload.authPin) {
    sessionStorage.setItem('wisetack:session', `${payload.loanAppId}:${Date.now().toString()}`)
  }
}


const staticPrequalInitToState = (payload) => {
  return{
    signupId: payload.signupId,
    prequalId: payload.prequalId,
    prequalStatus: "NOT_FOUND",
    personalDataProvided: false,
    dataInquiryList: [],
    staticPrequalInitialized : true
  }
}

const prequalToState = (payload) => {
  const phone = !!payload.dataInquiryList ? payload.dataInquiryList.find(element => element.type === 'BORROWER_PROVIDED_BORROWER_PHONE') : null;
  let mobileNumber = payload.mobileNumber;
  if (!mobileNumber && phone && phone.prefilledValue) {
    mobileNumber = phone.prefilledValue
  }
  const fieldsRequired = getFieldsRequired(payload.dataInquiryList, true);
  const fieldsValue = getFieldsValue(payload.dataInquiryList);
  const fieldsSource = getFieldsSource(payload.dataInquiryList);
  let prefilledCount = 0
  Object.keys(fieldsRequired).forEach((key) => {
    if (fieldsValue[key] && (!fieldsSource[key] || fieldsSource[key] === 'BR')) {
      fieldsRequired[key] = false;
    }
    if(fieldsValue[key]){
      prefilledCount = prefilledCount + 1;
    }
  })
  let plans = [];
  if (payload.offer && payload.offer.loanOfferDetailsList) {
    plans = payload.offer.loanOfferDetailsList.map((item) => {
      return convertOfferToPlan(item);
    });
    plans.sort((a, b) => a.months - b.months);
  }
  return {
    mobileNumber,
    signupId: payload.signupId,
    prequalId: payload.prequalId,
    prequalStatus: payload.prequalStatus,
    dataInquiryList: payload.dataInquiryList,
    prequalApplicationId: payload.applicationId,
    fieldsError: getFieldsError(payload.dataInquiryList),
    fieldsRequired,
    fieldsValue,
    fieldsSource,
    prefilledCount,
    status: payload.status,
    statusAt: Date.now(),
    maximumLoanAmount: payload.maximumLoanAmount,
    requestedLoanAmount: payload.requestedLoanAmount,
    merchantName: payload.merchantName,
    plans,
    expiresInDays: payload.expiresInDays,
    expDate: payload.expDate,
    selectedOfferId: payload.selectedOfferId,
    h5CreditScoreDisclosure: payload.h5CreditScoreDisclosure,
    adverseAction: payload.adverseAction,
    personalDataProvided: payload.personalDataProvided,
    parentId: payload.parentId
  }
}

const getLoanStatusName = (status) => {
  switch (status) {
    case 'CONDITIONAL_APPROVAL':
    case 'CONDITIONAL_APPROVAL_ACCOUNT_LINKED':
      return 'Action Required'
    case 'OFFER_AVAILABLE':
    case 'ACCOUNT_LINKED':
      return 'Loan Approved'
    case 'DOCUMENTS_SIGNED':
      return 'Documents Signed'
    case 'OFFER_CONVERTED':
    case 'PAYOUTS_CONVERTED':
      return 'Loan Confirmed'
    default:
      return ''
  }
}

const formatActiveLoans = (activeLoans) => {
  if (!activeLoans || !activeLoans.loanInfoList) {
    return activeLoans
  }
  for (const item of activeLoans.loanInfoList) {
    if (item.expDate) {
      item.expDate = moment(item.expDate).format("MMM. D, YYYY")
    }
    if (item.amount) {
      item.amount = formatAmount(item.amount)
    }
    if (item.status) {
      item.statusName = getLoanStatusName(item.status)
    }
  }
  return activeLoans
}

const consumerReducer = (state = initState, action) => {
  switch (action.type) {
    case CONSUMER_CLEAR_STATE:
      return initState;
    case API_REQUEST_STARTED:
      const apiRequests = {}
      if (action.payload && action.payload.requestId) {
        apiRequests[action.payload.requestId] = action.payload.requestType || true
      }
      return {
        ...state,
        isLoading: true,
        ssn4Error: "",
        errorMessage: "",
        fieldsError: {},
        initExpired: false,
        apiRequests: {
          ...state.apiRequests,
          ...apiRequests
        },
        apiRunningPath:action.apiRunningPath
      };
    case API_REQUEST_ENDED:
      const requests = {...state.apiRequests}
      if (action.payload && action.payload.requestId) {
        delete requests[action.payload.requestId]
      }
      return {
        ...state,
        isLoading: false,
        apiRequests: requests,
        apiRunningPath:null
      };
    case CONSUMER_API_ERROR:
      return {
        ...state,
        apiError: action.payload,
        errorMessage: action.payload.error ? action.payload.error.message : ""
      };
    case FIELD_ERROR:
      return {
        ...state,
        fieldsError: setFieldsError(state.fieldsError, action.payload),
      };
    case CONSUMER_ERROR:
      if (state.captureContext) {
        logAmplitudeEvent('Debit card collection', {
          status: 'fail',
          error: action.payload
        });
      }
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SSN4_ERROR:
      return {
        ...state,
        ssn4Error: action.payload,
      };
    case APPLICATION_EXPIRED:
      return {
        ...state,
        initExpired: true,
      };
    case MERCHANT_CREATE_LOAN:
      if (action.payload.initToken) {
        saveToken(action.payload.initToken);
        saveTokenTTL(action.payload.initToken);
      }
      if (action.payload.loanApplicationId) {
        saveLoanAppId(action.payload.loanApplicationId);
      }
      return {
        ...state,
        token: action.payload.initToken,
      };
    case CONSUMER_GET_LOAN_STATUS: {
      initSession(action.payload);
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      const newState = {
        ...state,
        accountMask: action.payload.accountMask,
        adverseAction: action.payload.adverseAction,
        autoPaymentsDecision: action.payload.autoPaymentsDecision,
        balanceDecision: action.payload.balanceDecision,
        bankName: action.payload.bankName,
        bankVerificationRequired: action.payload.bankVerificationRequired,
        dataInquiryList: action.payload.dataInquiryList,
        declineReasonsList: action.payload.declineReasonsList,
        doingBusinessAs: action.payload.doingBusinessAs,
        fieldsError: getFieldsError(action.payload.dataInquiryList),
        fieldsRequired: getFieldsRequired(action.payload.dataInquiryList),
        mobileNumber: action.payload.mobileNumber,
        fieldsValue: getFieldsValue(action.payload.dataInquiryList),
        firstName: action.payload.firstName,
        h5CreditScoreDisclosure: action.payload.h5CreditScoreDisclosure,
        loanAppId: action.payload.loanApplicationId,
        loanAppExpirationDate: action.payload.loanApplicationExpirationDate,
        merchantName: action.payload.merchantName,
        merchantId: action.payload.merchantId,
        moreInfoRequired: action.payload.moreInfoRequired,
        phoneVerified: action.payload.phoneVerified,
        emailVerificationRequired: action.payload.emailVerificationRequired,
        cancelReasonsList: action.payload.cancelReasonsList,
        rejectReasonsList: action.payload.rejectReasonsList,
        selectedLoanOfferId: action.payload.selectedLoanOfferId,
        selectedLoanOfferStatus: action.payload.selectedLoanOfferStatus,
        serviceCompletionDate: action.payload.serviceCompletionDate,
        status: action.payload.status,
        statusAt: Date.now(),
        statusId: action.payload.statusId,
        transactionAmount: action.payload.transactionAmount,
        lockRequired: action.payload.lockRequired,
        offerLock: action.payload.offerLock,
        vertical: action.payload.vertical,
        profileFound: action.payload.profileFound,
        prequalStatus: action.payload.prequalStatus,
        refunded: action.payload.refunded,
        totalRefundAmount: action.payload.totalRefundAmount,
        activeLoans: formatActiveLoans(action.payload.activeLoans),
        settlementDelay: action.payload.settlementDelay,
        settlementDate: action.payload.settlementDate,
        payoutsList: action.payload.payouts
      };
      if (action.payload.initToken) {
        newState.token = action.payload.initToken;
        // save token to localStorage
        saveToken(action.payload.initToken);
      }
      return newState;
    }
    case CONSUMER_SUBMIT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CONSUMER_UPDATE_LOAN:
      saveSubmitted(action.payload.loanApplicationId);
      updateSession(action.payload)
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      return {
        ...state,
        status: action.payload.status,
        loanAppId: action.payload.loanApplicationId,
        fieldsRequired: getFieldsRequired(action.payload.dataInquiryList),
        fieldsError: getFieldsError(action.payload.dataInquiryList),
        fieldsValue: getFieldsValue(action.payload.dataInquiryList),
        declineReasonsList: action.payload.declineReasonsList,
        rejectReasonsList: action.payload.rejectReasonsList,
        offerId: action.payload.offerId, // ?
        adverseAction: action.payload.adverseAction,
        h5CreditScoreDisclosure: action.payload.h5CreditScoreDisclosure,
        statusAt: Date.now(),
        bankVerificationRequired: action.payload.bankVerificationRequired,
        moreInfoRequired: action.payload.moreInfoRequired,
        submitDataRequestId: action.payload.submitDataRequestId,
        loanAppExpirationDate: action.payload.loanApplicationExpirationDate,
        ...selectedPayout(action.payload.loanPayoutDetailsList
            ? action.payload.loanPayoutDetailsList[0]
            : {}, state.payouts),
        activeLoans: formatActiveLoans(action.payload.activeLoans),
        pinVerified: action.payload.pinVerified,
        loanLimitExceeded: action.payload.loanLimitExceeded
      };
    case CONSUMER_GET_OFFERS:
      // convert API object to UI object
      let plans = action.payload.loanOfferDetailsList.map((item) => {
        return convertOfferToPlan(item);
      });
      let transactionAmount = state.transactionAmount;
      let offer = action.payload.loanOfferDetailsList.find(
        (item) => item.loanAmount != null && item.status !== "NOT_SELECTED"
      );
      if (offer) {
        transactionAmount = offer.loanAmount;
      }
      let selectedPlan = state.selectedPlan;
      for (const item of plans) {
        if (item.status !== 'NOT_SELECTED') {
          selectedPlan = item;
          break;
        }
      }
      plans.sort((a, b) => a.months - b.months);
      return {
        ...state,
        plans,
        selectedPlan,
        truthInLendingID: offer ? offer.truthInLendingID : null,
        transactionAmount,
        loanAppExpirationDate: action.payload.loanApplicationExpirationDate,
        lockRequired: action.payload.lockRequired,
        payouts: action.payload.loanPayoutDetailsList ? action.payload.loanPayoutDetailsList.map((item) => {
          return convertPayout(item);
        }) : [],
        payoutsList: action.payload.loanPayoutDetailsList ? action.payload.loanPayoutDetailsList.map((item) => {
          return item.id;
        }) : [],
        firstMonthPrepayment: action.payload.firstMonthPrepayment || false // to prevent undefined if FMP flag is null
      };
    case CONSUMER_ACCEPT_OFFER:
      console.log("CONSUMER_ACCEPT_xsOFFER.....", action.payload);
      return {
        ...state,
        selectedLoanOfferStatus: action.payload.loanOfferDetailsList[0].status,
        selectedLoanOfferId: action.payload.loanOfferDetailsList[0].id,
        selectedPlan: convertOfferToPlan(
          action.payload.loanOfferDetailsList[0]
        ),
        loanAppId: action.payload.loanAppId,
        offerAcceptedAt: Date.now(), // introduce change property to force switch to next page on offer accept
        truthInLending: action.payload.loanOfferDetailsList[0].truthInLending, // base64 PDF data
        truthInLendingID:
          action.payload.loanOfferDetailsList[0].truthInLendingID,
        emailVerificationRequired: action.payload.emailVerificationRequired,
        payouts: action.payload.loanPayoutDetailsList ? action.payload.loanPayoutDetailsList.map((item) => {
          return convertPayout(item);
        }) : []
      };
    case CONSUMER_GET_TRUTH_IN_LENDING:
      const loanOffer = action.payload.loanOfferDetailsList
        ? action.payload.loanOfferDetailsList[0]
        : {};
      return {
        ...state,
        truthInLending: loanOffer.truthInLending || state.truthInLending,
        truthInLendingID: loanOffer.truthInLendingID || state.truthInLendingID,
        loanServicer: action.payload.loanServicer,
        selectedPlan: convertOfferToPlan(loanOffer),
        payouts: action.payload.loanPayoutDetailsList ? action.payload.loanPayoutDetailsList.map((item) => {
          return convertPayout(item);
        }) : []
      };
    case CONSUMER_ACCEPT_TRUTH_IN_LENDING:
      if (action.payload.declineReason) {
        return {
          ...state,
          declineReason: action.payload.declineReason,
          adverseAction: action.payload.adverseAction,
          h5CreditScoreDisclosure: action.payload.h5CreditScoreDisclosure,
          emailVerificationRequired: action.payload.emailVerificationRequired,
          ...selectedPayout(action.payload.loanPayoutDetailsList
              ? action.payload.loanPayoutDetailsList[0]
              : {}, state.payouts)
        };
      }
      const loanOfferDetails = action.payload.loanOfferDetailsList
        ? action.payload.loanOfferDetailsList[0]
        : {};
      return {
        ...state,
        selectedLoanOfferStatus: loanOfferDetails.status,
        selectedLoanOfferId: loanOfferDetails.id,
        selectedPlan: convertOfferToPlan(loanOfferDetails),
        loanAppId: action.payload.loanAppId,
        truthInLendingAcceptedAt: Date.now(),
        ...selectedPayout(action.payload.loanPayoutDetailsList
            ? action.payload.loanPayoutDetailsList[0]
            : {}, state.payouts)
      };
    case CONSUMER_SUBMIT_PIN:
      return {
        ...state,
        ...action.payload,
      };
    case CONSUMER_GET_PERSONA_INQUIRIES:
      return {
        ...state,
        templateId: action.payload.templateId,
        activeInquiry: action.payload.activeInquiry,
        anySubmitted: !!action.payload.anySubmitted,
        anyFailed: !!action.payload.anyFailed,
        piiData: action.payload.piiData
      };
    case CONSUMER_SUBMIT_PHONE:
      return {
        ...state,
        fieldsRequired: getFieldsRequired(action.payload.dataInquiryList),
        dataInquiryList: action.payload.dataInquiryList,
        mobileNumber: action.payload.mobileNumber,
        submitPhoneRequestId: action.payload.submitPhoneRequestId,
        profileFound: action.payload.profileFound,
        firstName: action.payload.firstName
      };
    case CONSUMER_CREATE_LINK_TOKEN:
      if (action.payload.loanApplicationId && action.payload.linkToken.token) {
        localStorage.setItem(`link_token:${action.payload.loanApplicationId}`, action.payload.linkToken.token)
      }
      return {
        ...state,
        linkTokens: {...state.linkTokens, [action.payload.loanApplicationId]: action.payload.linkToken}
      }
    case CONSUMER_ACCEPT_PLAID_TOKEN: {
      const newState = {
        ...state,
        status: action.payload.status,
        autoPaymentsDecision: action.payload.autoPaymentsDecision,
        acceptPlaidTokenRequestId: action.payload.acceptPlaidTokenRequestId,
      };
      if (action.payload.institution) {
        newState.bankName = action.payload.institution.name;
      }
      if (action.payload.account) {
        newState.accountMask = action.payload.account.mask;
      }
      if (action.payload.balanceDecision) {
        newState.balanceDecision = action.payload.balanceDecision;
      }
      if (action.payload.moreInfoRequired) {
        newState.moreInfoRequired = action.payload.moreInfoRequired;
      }
      return newState;
    }
    case CONSUMER_PURCHASE_RECEIVED:
      let plan = state.selectedPlan;
      plan.starting = moment
        .unix(action.payload.newFirstPaymentDue / 1000)
        .format("MM/DD/YYYY");
      return {
        ...state,
        status: action.payload.newStatus || state.status,
        serviceCompletionDate: action.payload.serviceCompletionDate,
        confirmationRequestId: action.payload.confirmationRequestId,
        loanServicer: action.payload.loanServicer,
        selectedPlan: plan,
        ...selectedPayout(action.payload.loanPayout, state.payouts),
        settlementDate: action.payload.settlementDate,
        cardLastFourDigits: action.payload.cardLastFourDigits
      };
    case CONSUMER_OFFER_SELECTED:
      const o = action.payload.loanOfferDetailsList
        ? action.payload.loanOfferDetailsList[0]
        : {};
      return {
        ...state,
        selectedLoanOfferStatus: o.status,
        selectedLoanOfferId: o.id,
        selectedPlan: convertOfferToPlan(o),
        loanAppId: action.payload.loanApplicationId || state.loanAppId,
        loanAppExpirationDate: action.payload.loanApplicationExpirationDate,
        payouts: action.payload.loanPayoutDetailsList ? action.payload.loanPayoutDetailsList.map((item) => {
          return convertPayout(item);
        }) : []
      };
    case CONSUMER_LOCK_OFFER:
      return {
        ...state,
        lockRequestId: action.payload.lockRequestId
      }
    case CONSUMER_SMARTY_STREETS_AUTOCOMPLETE:
      return {
        ...state,
        suggestions: action.payload.suggestions
      }
    case CONSUMER_PREQUAL_SUBMIT:
      if (action.payload.applicationId) {
        savePrequalAppId(action.payload.applicationId);
      }
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      return {
        ...state,
        prequalSubmitRequestId: action.payload.requestId,
        ...prequalToState(action.payload)
      }
    case CONSUMER_PREQUAL_PATCH:
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      return {
        ...state,
        prequalPatchRequestId: action.payload.requestId,
        ...prequalToState(action.payload)
      }
    case CONSUMER_PREQUAL_GET:
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      return {
        ...state,
        prequalGetRequestId: action.payload.requestId,
        ...prequalToState(action.payload)
      }
    case CONSUMER_AUTOPREQUAL_INIT:
      setAmplitudeUserId(action.payload.customerId);
      datadogRum.setUserProperty("id", action.payload.customerId);
      return {
        ...state,
        ...staticPrequalInitToState(action.payload)
      }
    case CONSUMER_PREQUAL_PHONE:
      return {
        ...state,
        fieldsRequired: {
          ...state.fieldsRequired,
          phone: true
        }
      }
    case CONSUMER_CHECKBOX_SELECTION:
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [action.payload.page]: action.payload.selected,
          requestId: action.payload.requestId
        }
      }

    case CONSUMER_SESSION_EXPIRED:
      const continuePath = !!state.token ? '/' + state.token : null
      handleSessionExpiration()
      return {
        ...initState,
        continuePath
      }
    case CONSUMER_SELECT_PAYOUT:
      return {
        ...state,
        selectedPayout: action.payload
      }
    case EXPECTED_RUN_LENGTH:
      return {
        ...state,
        expectedRunLength: action.expectedRunLength,
      };
    case CONSUMER_GET_DOCUMENTS_CONTENT:
      return {
        ...state,
        ...convertDocumentsContent(action.payload)
      }
    case CONSUMER_GET_CAPTURE_CONTEXT:
      return {
        ...state,
        captureContext: action.payload.context
      }
    case CONSUMER_SUBMIT_CARD_DATA:
      logAmplitudeEvent('Debit card collection', {status: 'success'});
      return {
        ...state,
        status: action.payload.newStatus || state.status,
      }
    default:
      return state;
  }
};

export default consumerReducer;
