"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Messages = void 0;
var Messages = {
  ENTER_FIRST_NAME: "Enter first name",
  ENTER_VALID_FIRST_NAME: "Please enter valid first name",
  ENTER_LAST_NAME: "Enter last name",
  ENTER_VALID_LAST_NAME: "Please enter valid last name",
  TWO_LETTERS_MINIMUM: "Two letters minimum",
  ENTER_INCOME: "Please enter income",
  ENTER_VALID_INCOME: "Yearly income is below the minimum required.",
  ENTER_ZIP_CODE: "Please enter zip code",
  ENTER_EIN: "Please enter EIN",
  ENTER_EMAIL: "Please enter email",
  ENTER_VALID_WEBSITE: "Please enter a valid website",
  ENTER_VALID_EMAIL: "Please enter a valid email",
  ENTER_DOB: "Enter date of birth",
  INVALID_DATE_FORMAT: "Format: MM/DD/YYYY",
  INVALID_DATE: "Invalid date",
  INVALID_FUTURE_DATE: "Date cannot be a future date.",
  INVALID_PAST_DATE: "Date cannot be older than 120 years.",
  INVALID_PAST_SCHEDULED_COMPLETION_DATE: "Completion date is in the past. If the job is done, click back to confirm your loan.",
  INVALID_PAST_TO_OFFER_COMPLETION_DATE: function INVALID_PAST_TO_OFFER_COMPLETION_DATE(loanAppExpirationDate) {
    return "Completion date is past your offer expiration of " + loanAppExpirationDate + ". Consider reapplying later.";
  },
  ENTER_SSN4: "Enter last 4 of SSN",
  INVALID_SSN4: "Invalid SSN4",
  ENTER_VALID_SSN4: "Enter valid Last 4 Digits of SSN",
  ENTER_SSN4_HIDDEN: "Enter last 4 of SSN to agree",
  ENTER_SSN: "Please enter SSN",
  ENTER_VALID_SSN: "Please enter valid SSN",
  ENTER_ADDRESS: "Please enter address",
  ENTER_CITY: "Please enter city",
  ENTER_STATE: "Please enter state",
  ENTER_VALID_STATE: "Please enter valid state",
  ENTER_EMPLOYER: "Please enter employer",
  ENTER_PIN: "Enter code please",
  ENTER_PHONE: "Enter mobile number",
  ENTER_VALID_PHONE: "Please enter a valid phone number (only digits allowed)",
  ENTER_VALID_PHONE_ALT: "Please enter a valid phone number",
  ENTER_LOAN_PURPOSE: "Please enter valid description",
  ENTER_LOAN_PURPOSE_MIN: "Please type at least 3 characters",
  ENTER_VALID_JOB_ID: "Please enter valid Job ID",
  ENTER_AMOUNT: "Enter amount",
  ENTER_VALID_AMOUNT: "Should be between $500 and $15,000",
  ENTER_VALID_DATE: "Please enter valid date",
  ENTER_USERNAME: "Enter phone number or email",
  ENTER_VALID_TLD: "Please enter a valid Email domain",
  ENTER_ROUTING_NUMBER: "Please enter a valid Routing number",
  ENTER_ACCOUNT_NUMBER: "Please enter a valid Account number",
  ENTER_BUSINESS_LEGAL_NAME: "Enter Business Legal Name",
  ENTER_DOING_BUSINESS_AS: "Enter Doing Business As",
  ENTER_INDUSTRY: "Select Industry",
  ENTER_INDUSTRY_FREE_TEXT: "Please specify your industry",
  UNSUPPORTED_SYMBOLS: "Unsupported symbols used",
  MAX_LENGTH_120_EXCEEDED: "Field max length (120 symbols) exceeded",
  INVALID_DOING_BUSINESS_AS: "Please enter a valid DBA",
  ENTER_CARD_EXPIRATION_DATE: "Enter expiration date",
  INVALID_CARD_EXPIRATION_DATE_FORMAT: "Format: MM/YYYY"
};
exports.Messages = Messages;