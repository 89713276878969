import React from "react";
import classNames from "classnames";
import styles from "./LearnMorePrequalModal.module.scss";
import image from "@wisetack/assets/img/info-icon.svg";

const LearnMorePrequalModal = () => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="learnMorePrequalModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`learnMorePrequalModalLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className={classNames("modal-header", styles.modalHeader)}>
                        <img src={image} alt="logo"/>
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-learnMorePrequalModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <h1>Prequalification</h1>
                        <p>
                            When you prequalify, you get an estimate of how much you can borrow and on what terms. Checking if you qualify does not affect your credit score. To learn more, visit <a href="https://www.wisetack.com/faqs" target="_blank" rel="noopener noreferrer">wisetack.com/faqs</a>.
                        </p>
                        <p>
                            To see actual terms, you can continue with the loan application provided by&nbsp;the&nbsp;merchant.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnMorePrequalModal;
