import React, {useEffect, useState} from "react"

import classNames from "classnames";
import moment from "moment";
import uuid from "uuid";
import {connect} from "react-redux";

import Form from "@wisetack/shared-ui/components/Form";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Container from "@wisetack/shared-ui/components/Container";
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator"
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {submitData} from "../store/actions/consumerActions";

import styles from "./ConsumerVerifyMailPage.module.scss";

const pageName = "Email Confirmation Page"
const validator = new BorrowerFieldValidator(pageName);

const ConsumerVerifyEmailPage = (props) => {
    function logAmplitude(eventName) {
        let loanId = props.loanAppId.substr(0, 8);
        let logProps = {
            loanId: loanId,
            merchantName: props.merchantName,
            merchantId: props.merchantId,
            status: props.status,
            page: pageName
        }

        logAmplitudeEvent(eventName, logProps);
    }

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useState({ email: '', error: '', suggestion: '' });

    let btnDisabled = state.email === '' || state.error !== ''
    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });


    function handleSubmit(event) {
        event.preventDefault();
        logAmplitude("Pressed Confirm Email Button");
        saveMail();
        if (props.serviceCompletionDate && moment(props.serviceCompletionDate).isAfter(moment(), 'day')) {
            props.history.push("/purchase_confirm_pending");
        } else {
            props.history.push("/purchase_confirm");
        }
    }

    function saveMail() {
        let payload = { verifiedEmail: state.email }
        props.submitData(props.loanAppId, payload, uuid.v4());
    }

    function validate() {
        let errorMessage = validator.validateEmail(state.email, suggestion => {
            if (suggestion) {
                setState({ ...state, suggestion: suggestion });
            }
        });
        if (errorMessage) {
            setState({ ...state, error: errorMessage });
        }
    }

    return (
        <Container>
            <PageHeader progress="20%">
                <div>Confirm your email</div>
                <div style={{ textAlign: "center" }}>
                    Enter your email address again for confirmation, so we can send you important information about your loan.
                </div>
            </PageHeader>
            <Form>
                <FormRow>
                    <FormInput
                        type='email'
                        name='email'
                        label='Email address'
                        value={state.email}
                        onChange={event => { setState({ email: event.target.value, error: '', suggestion: '' }) }}
                        onBlur={validate}
                        errors={{ email: state.error }}
                        suggestions={{ email: state.suggestion }}
                    />
                </FormRow>
                <FormRow>
                    <button onClick={handleSubmit} className={btnClasses}>
                        CONFIRM EMAIL
                    </button>
                </FormRow>
            </Form>
        </Container>
    )
}

const mapStateToProp = (props, state, name) => {
    props[name] = state.consumer[name];
}

const mapStateToProps = state => {
    let props = {};
    mapStateToProp(props, state, "loanAppId")
    mapStateToProp(props, state, "merchantName")
    mapStateToProp(props, state, "merchantId")
    mapStateToProp(props, state, "status")
    return props;
};

export default connect(
    mapStateToProps,
    { submitData }
)(ConsumerVerifyEmailPage);
