import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Log from "@wisetack/shared-ui/utils/Log";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {getCaptureContext, getZip, submitCardData} from "../store/actions/consumerActions";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {LoaderWithMessage} from "../components/LoaderWithMessage";
import classNames from "classnames";
import styles from "./ConsumerCardCollectionPage.module.scss";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import ConsumerCardCollectionLearnMoreModal from "./ConsumerCardCollectionLearnMoreModal";
import Script from 'react-load-script'
import {BorrowerFieldValidator} from "@wisetack/shared-ui/utils/BorrowerFieldValidator";
import beep from "@wisetack/shared-ui/utils/Beep";
import {trackPageActivity} from "../utils/addressPageActivity";
import FormCardExpirationDateInput from "@wisetack/shared-ui/components/FormCardExpirationDateInput";
import ErrorWithIcon from "@wisetack/shared-ui/components/ErrorWithIcon";
import FormMicroformInput from "@wisetack/shared-ui/components/FormMicroformInput";
import styled from 'styled-components'

const pageName = "Card Collection Page";

const cybersourceMicroformUrl = "https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js";

const firstMonthPaymentBoxClasses = classNames({
    [styles.firstMonthPayment]: true,
    "row": true
});

const firstMonthPaymentTitleClasses = classNames({
    [styles.title]: true,
    "col-8": true
});

const firstMonthPaymentAmountClasses = classNames({
    [styles.title]: true,
    "col-4": true
});

const StyledWrapper = styled.div`
    .form-row {
        .form-group {
            input {
                font-family: "Helvetica", "Arial", sans-serif;
            }
        }
        position: relative;
    }
`

class ConsumerCardCollectionPage extends React.Component {

    state = {
        zipCode: "",
        month: "",
        year: "",
        expirationDate: "",
        checkedZip: true,
        checkingZipCode: false,
        validZipCode: false,
        dummyCvc: "",
        cardNumberFieldFocused: false,
        cardNumberFieldFilled: false,
        securityCodeFieldFocused: false,
        securityCodeFieldFilled: false,
        isCardNumberValid: false,
        isSecurityCodeValid: false,
    };

    microform = null;

    constructor(props) {
        super(props);
        this.validator = new BorrowerFieldValidator(pageName);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.logProps = {
            loanId: this.props.loanAppId.substr(0, 8),
            merchantName: this.props.merchantName,
            page: pageName,
        };
        logAmplitudeEvent(pageName, this.logProps);
        this.props.getCaptureContext();
    }

    componentDidUpdate(prevProps) {
        if (this.props.initExpired) {
            Log.info(this.props.initExpired, `initExpired`);
            this.props.history.push("/expired");
        } else if (this.props.status === "CONDITIONAL_APPROVAL_DEBIT_ADDED") {
            this.props.history.push("/link_bank");
        }
    }

    onScriptError = () => {
        console.error('There was an issue loading the 0.11/flex-microform.min.js script');
    }

    onScriptLoad = () => this.initMicroform();


    setCardNumberFocus = (focused) => {
        this.setState({
            cardNumberFieldFocused: focused
        })
    }
    setCardNumberFilled = (filled) => {
        this.setState({
            cardNumberFieldFilled: filled
        })
    }
    setCardNumberValid = (valid) => {
        this.setState({
            isCardNumberValid: valid
        })
    }
    setSecurityCodeFocus = (focused) => {
        this.setState({
            securityCodeFieldFocused: focused
        })
    }
    setSecurityCodeFilled = (filled) => {
        this.setState({
            securityCodeFieldFilled: filled
        })
    }
    setSecurityCodeValid = (valid) => {
        this.setState({
            isSecurityCodeValid: valid
        })
    }


    initMicroform = () => {
        try {
            const microformStyles = {
                'input': {
                    'font-size': '18px',
                    'font-family': 'Montserrat, Helvetica, Arial, sans-serif',
                    'color': 'rgb(49, 54, 76)',
                    'font-weight': '400',
                },
                ':focus': {'color': 'rgb(49, 54, 76)'},
                ':disabled': {'cursor': 'not-allowed'},
                'valid': {'color': 'rgb(49, 54, 76)'},
                'invalid': {'color': 'rgb(49, 54, 76)'},
                '::placeholder': {
                    'font-size': '12.5px',
                    'color': 'rgb(111, 117, 149)',
                    'font-weight': '300'
                }
            };
            const flex = new window.Flex(this.props.captureContext);
            this.microform = flex.microform({styles: microformStyles});
            const cardNumber = this.microform.createField('number', {placeholder: ''});
            const securityCode = this.microform.createField('securityCode', {placeholder: ''});

            cardNumber.load('#card-number-container');
            securityCode.load('#security-code-container');

            cardNumber.on('focus', () => {this.setCardNumberFocus(true)})
            cardNumber.on('blur', () => {this.setCardNumberFocus(false)})
            cardNumber.on('change', (data) => {this.setCardNumberValid(data.valid); this.setCardNumberFilled(!data.empty)})

            securityCode.on('focus', () => {this.setSecurityCodeFocus(true)})
            securityCode.on('blur', () => {this.setSecurityCodeFocus(false)})
            securityCode.on('change', (data) => {this.setSecurityCodeValid(data.valid); this.setSecurityCodeFilled(!data.empty)})
        } catch (err) {
            console.error('Error creating Flex microform', err);
        }
    }

    isFormValid = () => {
        return this.microform && this.state.isSecurityCodeValid && this.state.isCardNumberValid && this.state.validZipCode && this.state.month && this.state.year;
    }

    getEditValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    validateIsRealZipcode = async (val) => {

        this.setState({
            checkingZipCode: true
        });

        const result = await getZip(val);
        const valid = !!result.state;

        this.setState({
            checkingZipCode: false,
            checkedZip: true,
            validZipCode: valid
        })

        if (!valid) {
            this.setError('zipCode', 'Invalid zip code');
            logAmplitudeEvent('Validation Error', {
                application: "Borrower",
                field: "Zip Code",
                value: val,
                message: "Invalid zip code",
                page: pageName
            });
        }
    };

    handleOnSubmitClick = () => {

        const options = {
            expirationMonth: this.state.month,
            expirationYear: this.state.year
        };

        this.microform.createToken(options, (err, token) => {
            if (err) {
                console.error(err);
            } else {
                this.props.submitCardData(this.props.loanAppId, token, this.state.zipCode);
                this.clearForm();
            }
        });

    };

    clearForm = () => {
        this.setState({
            zipCode: "",
            month: "",
            year: "",
            expirationDate: "",
            checkedZip: true,
            validZipCode: false
        });
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;

        val = val.replace(/\D/g, "");

        if (name === "zipCode") {

            if (val.length > 5) {
                beep();
                return;
            } else if (val.length === 5) {
                // Async request; internally sets state.
                this.validateIsRealZipcode(val);
            } else {
                this.setError('zipCode', '');
                this.setState({
                    checkedZip: false,
                    validZipCode: false
                });
            }
        }

        this.setState({[name]: val});
    }

    render() {

        const {
            status,
            isLoading,
            errorMessage,
            expectedRunLength,
            captureContext
        } = this.props;


        const showContent = status && !isLoading && captureContext;

        const btnDisabled = !this.isFormValid();

        const submitBtnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        return (
            <>
                <Container>
                    <ConsumerCardCollectionLearnMoreModal loanExpirationDate={this.props.loanAppExpirationDate}/>
                    <PageHeader progress="40%">
                        {showContent ? (
                            <Fragment>
                                <div>
                                    Add a debit card
                                </div>
                            </Fragment>
                        ) : null}
                        {showContent ? (
                            <div className={styles.content} style={{textAlign: "center"}}>
                                <p>
                                    In order to process your application, we will
                                    <br/>need a debit card on file to charge the
                                    <br/>amount below around service completion.
                                    <br/>You may see a pending charge today.
                                    <span
                                        data-toggle="modal"
                                        data-target="#consumerCardCollectionLearnMoreModal"
                                        onClick={() => {
                                            logAmplitudeEvent("Opened Card Collection Learn More Modal", this.logProps);
                                        }}
                                        style={{display: "inline-block"}}>
                                        &nbsp;
                                        Learn more
                                    </span>
                                </p>

                            </div>
                        ) : null}
                    </PageHeader>
                    {showContent ? (
                        <div className={firstMonthPaymentBoxClasses}>
                            <div className={firstMonthPaymentTitleClasses} style={{textAlign: "left"}}>
                                FIRST PAYMENT:
                            </div>
                            <div className={firstMonthPaymentAmountClasses}
                                 style={{textAlign: "right"}}>${this.props.selectedPlanAmount}</div>
                        </div>
                    ) : null}
                    <LoaderWithMessage isLoading={isLoading} duration={expectedRunLength}/>
                    {showContent ? (
                        <div style={{paddingBottom: "15px"}}>
                            <StyledWrapper>
                                <Form>
                                    <FormRow>
                                        <FormMicroformInput
                                            type="text"
                                            name="card-number-container"
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            focused={this.state.cardNumberFieldFocused}
                                            filled={this.state.cardNumberFieldFilled}
                                            label={'Debit Card Number'}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormCardExpirationDateInput
                                            name="expirationDate"
                                            label="Expiration date"
                                            month={this.getEditValue("month")}
                                            year={this.getEditValue("year")}
                                            onChange={this.handleOnChange}
                                            onBlur={() => {
                                                const error = this.validator.validateCardExpirationDate(this.getEditValue("month"), this.getEditValue("year"));
                                                this.setError("expirationDate", error);
                                            }}
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                        />
                                        <FormMicroformInput
                                            type="text"
                                            name="security-code-container"
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            focused={this.state.securityCodeFieldFocused}
                                            filled={this.state.securityCodeFieldFilled}
                                            label={'CVC'}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormInput
                                            type="text"
                                            min="0"
                                            max="99999"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            name="zipCode"
                                            label={this.state.checkingZipCode ? 'checking...' : "Zip code"}
                                            value={this.getEditValue("zipCode")}
                                            innerRef={this.zipInput}
                                            onChange={this.handleOnChange}
                                            onBlur={(evt) => {
                                                const val = evt.target.value;
                                                if (!this.state.checkedZip){
                                                    const error = this.validator.validateZipCode(val);
                                                    this.setError('zipCode', error);
                                                }
                                            }}
                                            errors={this.state.errors}
                                            fieldsError={this.props.fieldsError}
                                            turnOffAutoFill={true}
                                            onKeyUp={(e) => trackPageActivity("zipOnKeyPress", e)}
                                        />
                                    </FormRow>
                                </Form>
                            </StyledWrapper>
                            <br/>
                            <ErrorWithIcon
                                pageName={pageName}>{errorMessage ? "Please try again using a different debit card with a positive bank balance." : null}</ErrorWithIcon>
                            <br/>
                            <button
                                onClick={this.handleOnSubmitClick}
                                className={submitBtnClasses}
                                data-test-id="submit-button">SUBMIT
                            </button>
                        </div>
                    ) : null}
                </Container>
                {showContent ? (<Script
                    url={cybersourceMicroformUrl}
                    onLoad={this.onScriptLoad}
                    onError={this.onScriptError}/>) : null}
            </>
        );
    }
}

ConsumerCardCollectionPage.propTypes = {
    getCaptureContext: PropTypes.func.isRequired,
    submitCardData: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    errorMessage: state.consumer.errorMessage,
    status: state.consumer.status,
    selectedLoanOfferStatus: state.consumer.selectedLoanOfferStatus,
    captureContext: state.consumer.captureContext,
    initExpired: state.consumer.initExpired,
    lockRequired: state.consumer.lockRequired,
    expectedRunLength: state.consumer.expectedRunLength,
    zipCode: state.zipCode,
    month: state.month,
    year: state.year,
    expirationDate: state.expirationDate,
    selectedPlanAmount: state.consumer.selectedPlan.amount,
    loanAppExpirationDate: state.consumer.loanAppExpirationDate
});

export default connect(mapStateToProps, {getCaptureContext, submitCardData})(
    ConsumerCardCollectionPage
);
