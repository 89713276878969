import React, {Fragment} from "react";
import {connect} from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import refund from "@wisetack/assets/img/refund-icon-big.svg";
import styles from "./ConsumerConfirmationPage.module.scss";
import Loader from "@wisetack/shared-ui/components/PulseLoader";

class ConsumerRefundedPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        logAmplitudeEvent("Refunded Page", {});
    }

    render() {
        const LoaderWithMessage = () => {
            if (!this.props.isLoading) return null;
            return (
                <div>
                    <Loader/>
                    <div className={styles.message}>Wait a moment please...</div>
                </div>
            );
        };
        return (
            <Container>
                <PageHeader progress="99%">
                    <Fragment>
                        <strong>Loan refunded</strong>
                    </Fragment>
                    <Fragment/>
                </PageHeader>
                <LoaderWithMessage/>
                <div className={styles.content}>
                    <div style={{textAlign: "center"}}>
                        <img className={styles.image} src={refund} alt="refund"/>
                    </div>
                    <p style={{textAlign: "center"}}>
                        We have refunded you <b>${this.props.totalRefundAmount}</b> for this loan.
                    </p>
                    <p style={{textAlign: "center"}}>
                        Please contact <a href="mailto:support@wisetack.com">support@wisetack.com</a> for additional
                        questions or assistance.
                    </p>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    loanAppId: state.consumer.loanAppId,
    isLoading: state.consumer.isLoading,
    totalRefundAmount: state.consumer.totalRefundAmount
});

export default connect(mapStateToProps, {})(ConsumerRefundedPage);
