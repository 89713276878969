import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Error from "@wisetack/shared-ui/components/Error";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import PdfModalZoom from "@wisetack/shared-ui/components/PdfModalZoom";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import image from "@wisetack/assets/img/declined.svg";

import {prequalGet} from "../store/actions/consumerActions";
import usePrequalFlow from "../hooks/usePrequalFlow";
import styles from "./PrequalDeclinedPage.module.scss";
import {formatExpDate} from "../utils/format";

const pageName = 'Prequal Declined Page'

function PrequalDeclinedPage({prequalGet}) {
    const expDate = useSelector(state => state.consumer.expDate);
    const adverseAction = useSelector(state => state.consumer.adverseAction);
    const h5CreditScoreDisclosure = useSelector(state => state.consumer.h5CreditScoreDisclosure);
    const errorMessage = useSelector(state => state.consumer.errorMessage);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('consumer');
    const {applicationId, logAmplitude} = usePrequalFlow(prequalGet, newRequest);

    useEffect(() => {
        logAmplitude(pageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!errorMessage || !!requestError) {
            logAmplitude(pageName, 'Error', {message: errorMessage || requestError});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, requestError]);

    return <Container>
        <PageHeader progress="99%">
            <div>Unfortunately, we are unable to prequalify your application</div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="declined-logo"/>
        <div className={styles.message}>
        </div>
        <LoaderWithMessage loading={!!requestId && requestInProgress}/>
        { applicationId && !requestInProgress &&
            <>
                <div className={styles.message}>
                    We’ve emailed you the following documents, which include more information about our decision.
                </div>
                <div style={{marginBottom: "40px"}}>
                    { adverseAction &&
                        <div className={styles.buttonLink} data-toggle="modal" data-target="#adverseActionModal">
                            Notice of Action
                        </div>
                    }
                    { h5CreditScoreDisclosure &&
                        <div className={styles.buttonLink} data-toggle="modal" data-target="#h5CreditScoreDisclosureModal">
                            Credit Score Disclosure
                        </div>
                    }
                </div>
                <div className={styles.message}>
                    We are grateful you considered us. While the decision is locked until <b>{formatExpDate(expDate)}</b>, you may subsequently reapply, which will not affect your credit score. If you have further questions, please&nbsp;contact <br/><a href="mailto:support@wisetack.com">support@wisetack.com</a>.
                </div>
            </>
        }
        <Error pageName={pageName}>{requestError || errorMessage}</Error>
        { adverseAction &&
            <PdfModalZoom name="adverseActionModal" data={adverseAction} headerTitle="Adverse Action"/>
        }
        { h5CreditScoreDisclosure &&
            <PdfModalZoom name="h5CreditScoreDisclosureModal" data={h5CreditScoreDisclosure} headerTitle="Credit Score Disclosure"/>
        }
    </Container>
}

export default connect(null, {prequalGet})(
    PrequalDeclinedPage
);

